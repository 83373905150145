import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {Helmet} from "react-helmet-async";
import './App.css';
//import Topbar from './pages/Topbar';
import Navbar from './pages/Navbar';
import Footer from './pages/Footer';
import AboutUsMain from './pages/about/AboutUsMain';
import HomePage from './pages/HomePage';
import ServiceMain from './pages/service/ServiceMain';
import ContactMain from './pages/contact/ContactMain';
import AdmissionDetails from './pages/admission/AdmissionDetails';
import OnlineApplication from './pages/admission/OnlineApplication';
import AboutLibrary from './pages/library/AboutLibrary';
import PhotoGallery from './pages/gallery/PhotoGallery';
import VideoGallery from './pages/gallery/VideoGallery';
import Gallery from './pages/Gallery';
import VJJournal from './pages/publications/VJJournal';
import YouTubeChannel from './pages/publications/YouTubeChannel';
import LatestArrivalsView from './pages/library/LatestArrivalsView';
import ACE from './pages/publications/ACE';
import VJToday from './pages/publications/VJToday';
import Tatavaviveka from './pages/publications/Tatavivaveka';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FlashNews from './pages/FlashNews';
import VisionMission from './pages/about/VisionMission';
import History from './pages/about/History';
import Formation from './pages/about/Formation';
import Accomodation from './pages/service/Accomodation';
import TeamMain from './pages/team/TeamMain';
import TeamOfficialMain from './pages/team/TeamOfficialMain';
//import TheologyContent from './pages/academics/TheologyContent';
import ApplicationStatus from './pages/admission/ApplicationStatus';
import PrincipalMessage from './pages/about/PrincipalMessage';
import ResidentialFaculty from './pages/team/ResidentialFaculty';
import VJDEPThContent from './pages/academics/VJDEPThContent';
import SocityHistory from './pages/about/SocityHistory';
import BThContent from './pages/academics/BThContent';
//import FieldWorkContent from './pages/fieldwork/FieldWorkContent';
import MThContent from './pages/academics/MThContent';
import DoctoralContent from './pages/academics/DoctoralContent';
import DiplomaContent from './pages/academics/DiplomaContent';
import CertificateContent from './pages/academics/CertificateContent';
import MethodologyContent from './pages/academics/MethodologyContent';
import StudentFieldWork from './pages/student/StudentFieldWork';
import TimelineMain from './pages/TimeLineVJ/TimelineMain';
//import './css/style.css';

function App() {
  return (
    <div>
    <Helmet>
      <title>Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/" />
    </Helmet>
    <Navbar />
    <FlashNews />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutUsMain />} />
      <Route path="/about/vjtopstories" element={<TimelineMain />} />
      <Route path="/about/vision-mission" element={<VisionMission />} />
      <Route path="/about/history" element={<History />} />
      <Route path="/about/formation" element={<Formation />} />
      <Route path="/about/residentialFaculty" element={<ResidentialFaculty />} />
      <Route path="/about/visitingFaculty" element={<TeamMain />} />
      <Route path="/about/officials" element={<TeamOfficialMain />} />
      <Route path="/about/sjinfo" element={<SocityHistory />} />
      <Route path="/academics/methodolgoy" element={<MethodologyContent />} />
      <Route path="/academics/bachealor" element={<BThContent />} />
      <Route path="/academics/master" element={<MThContent />} />
      <Route path="/academics/doctoral" element={<DoctoralContent />} />
      <Route path="/academics/diploma" element={<DiplomaContent />} />
      <Route path="/academics/certificate" element={<CertificateContent />} />
      <Route path="/academics/depth" element={<VJDEPThContent/>} />
      <Route path="/publications" element={<ServiceMain />} />
      <Route path="/services/accomodation" element={<Accomodation />} />
      {/*<Route path="/project" element={<ProjectMain />} />
      <Route path="/testimonial" element={<TestimonialMain />} />*/}
      <Route path="/students/fieldwork" element={<StudentFieldWork />} />
      <Route path="/contact" element={<ContactMain />} />
      <Route path="/admission/details" element={<AdmissionDetails />} />
      <Route path="/admission/application-form" element={<OnlineApplication />} />
      <Route path="/admission/application-status" element={<ApplicationStatus />} />
      <Route path="/library/about" element={<AboutLibrary />} />
      <Route path="/library/latest" element={<LatestArrivalsView />} />
      <Route path="/gallery/photo" element={<Gallery task={"App"}/>} />
      <Route path="/gallery/video" element={<VideoGallery />} />
      <Route path="/publications/vj-journal" element={<VJJournal />} />
      <Route path="/publications/youtube" element={<YouTubeChannel />} />
      <Route path="/publications/ave" element={<ACE />} />
      <Route path="/publications/vjtoday" element={<VJToday />} />
      <Route path="/publications/tattvaviveka" element={<Tatavaviveka />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/about/principalmessage" element={<PrincipalMessage />} />
    </Routes>
    <Footer />
    </div>
  );
}
export default App;