import React from 'react';
import { useSnackbar } from 'notistack';

export default function DocBPHCert(props) {
    const inputRef = React.useRef();
    const {setCertProofFile }=props;
    const { enqueueSnackbar } = useSnackbar();

   const handleFileSelection = (event)=>{
     event.preventDefault();
     if (event.target.files && event.target.files.length > 0) {
      setCertProofFile(event.target.files[0]);
      enqueueSnackbar("File Selected - "+ event.target.files[0].name,{variant:'info'});
   }else{
    enqueueSnackbar("No File Selected",{variant:'info'});
   }
  }
    return (
      <div style={{textAlign:"left"}}> 
      <p>Attested True Copy of  </p>
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
        />
        
</div>
      
    )

}
