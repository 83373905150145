import React, {useEffect, useState} from 'react';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import Preloader from '../components/Preloader/Preloader';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../App.css';
import useFullPageLoader from '../hooks/useFullPageLoader';
import CustomSpinner from '../hooks/customerSpinner/CustomSpinner';

const config=require('../config.json');
const imgProps={
    0: {
        items: 1,
    },
    1024: {
        items: 2,
        itemsFit: 'contain',
    }
  };


export default function CaroselMultiView() {
    const [galleryImages, setGalleryImages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const handleDragStart = (e) => e.preventDefault();

   /* const items = [
        <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
        <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
        <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
      ];*/


    useEffect(() => {
        fetchGalleryImages();
    }, [])

    const fetchGalleryImages =async()=>{
        try{
            setLoaded(false);
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
          //const fileName='images/gallery/';
         // const bucketName="images.stjosephhospitaldindigul.in";
      //await axios.get(`${config.api.invokeUrl}/images/gallery?fileName=${keyName}&bucketName=${bucketName}`,{
        //const fileName='images/doctor/';
        const fileName='images/slider';
        const bucketName="images.vidyajyoticollege.in";
      await axios.get(`${config.api.invokeURL}/gallery/slider?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
              //Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log("Response : ", response);
            const finalList=[];
            const finalList1=[];
            if(response.data.length>0){
              for (const i in response.data){
                const tValue=response.data[i];
                const tPathName=tValue.split("/");
                const tBreakUp=tPathName[2];
                const tempValue={};
                tempValue.fullPathName=response.data[i];
                tempValue.fileName=tBreakUp;
               // const tdoctorName=tBreakUp[1];
               // const tempDName=tdoctorName.split(".");
               // tempValue.doctorName=tempDName[0];
               // console.log("tempvalue : ", tempValue);
               // tempValue.projectSize=tBreakUp[2];
                //tempValue.projectDate=tBreakUp[3];
                const temp=<img src={`${config.s3.ImageinvokeURL}${response.data[i]}`} onDragStart={handleDragStart} role='presentation' key={i} alt={tBreakUp} style={{padding:"5px", borderRadius:"20px", height:"440px"}}/>
                finalList1.push(temp);
                finalList.push(tempValue);
              }
            }      
            //console.log("list : ", finalList1);

            //console.log("items : ", items);
            setGalleryImages(finalList1);
            /*setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message:response.data.length + " - Images are found in Slider section"
            }); */
    
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
            setLoaded(true);
            window.scrollTo(0,0); 
        }).catch((error)=>{
            setLoaded(true);
            window.scrollTo(0,0); 
        })
    }catch(error){
        setLoaded(true);
        window.scrollTo(0,0); 
    }
    setLoaded(true);
    }
    
  return (
    <div>
       {/*<Preloader show={loaded ? false : true} />
        <div style={{textAlign:"center"}}> {loader} </div>*/}
        {/*<ImageSpinner />*/}
       {!loaded &&  <CustomSpinner />}
        <div className="container  px-0" style={{height:"450px"}}>
            <div id="carouselId" className="carousel slide" data-bs-ride="carousel" style={{height:"450px"}}>
                <div className="carousel-inner" role="listbox" style={{height:"450px"}}>
                    {loaded && galleryImages.length>0 && <AliceCarousel mouseTracking items={galleryImages}  
                    autoPlay infinite 
                    responsive={imgProps}
                    animationDuration={1200}
                    animationType="fadeout"
                    autoPlayDirection="rtl"
                    disableButtonsControls={true}
                    autoHeight={true}
                    disableDotsControls={true}
                    //paddingLeft={10}
                    //paddingRight={10}
                    //innerWidth={10}
                    />  } 
                    
                </div>
            </div>
        </div>
    </div>
  )
}
