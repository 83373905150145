import React from 'react';
import { useSnackbar } from 'notistack';

export default function DocSuperiorLetter(props) {
    const inputRef = React.useRef();
    const {setRecommenLetterFile }=props;
    const { enqueueSnackbar } = useSnackbar();

   const handleFileSelection = (event)=>{
     event.preventDefault();
     if (event.target.files && event.target.files.length > 0) {
      setRecommenLetterFile(event.target.files[0]);
      enqueueSnackbar("File Selected - "+ event.target.files[0].name,{variant:'info'});
      
   }else{
    enqueueSnackbar("No File Selected",{variant:'info'});
   }
  }
    return (
      <div style={{textAlign:"left"}}> 
      <p>  A letter of recommendation from the Superior </p>
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
        />
      </div> 
    )
}