import React, { useState, useEffect } from 'react';
//import { Player } from 'video-react';
//import PublicationHeader from './PublicationHeader';
//import FaceView from '../FaceView';
import Preloader from '../../components/Preloader/Preloader';
import NewFaceView from '../NewFaceView';
import {Helmet} from "react-helmet-async";

const playList=[{
  id:1,
  url:"https://www.youtube.com/embed/tbB_ZFyk3Ns",
  title:"VJ2022  Farewell 19/03/2022",
},{
    id:2,
    url:"https://www.youtube.com/embed/fZX18rE327A",
    title:"Inaugural Mass Vidya Jyoti College - New Delhi 10.08.2020",
},{
  id:3,
  url:"https://www.youtube.com/embed/BmTR_zL_tZo",
  title:"Karwani Muhabat 2017",
},{
  id:4,
  url:"https://www.youtube.com/embed/5CTMn2W7Dkg",
  title:"Ministry Presentation 2016",
},{
  id:5,
  url:"https://www.youtube.com/embed/4hLz9Y67NU8",
  title:"Asha  2016",
},{
  id:6,
  url:"https://www.youtube.com/embed/im9gcaHZqI4",
  title:"Balmela - 2015",
},{
  id:7,
  url:"https://www.youtube.com/embed/zZ2Wd9gd-Ug",
  title:"Diaconate 2016",
},{
  id:8,
  url:"https://www.youtube.com/embed/44TOjoE9vfg",
  title:"Theological Drama",
},{
  id:9,
  url:"https://www.youtube.com/embed/39iuJK34MyI",
  title:"Together along the way",
},{
  id:10,
  url:"https://www.youtube.com/embed/Ik7IG30en_s",
  title:"Theological Drama",
},{
  id:11,
  url:"https://www.youtube.com/embed/5OkYkS8sIQc",
  title:"Diaconate Ordination - 2017 || Vidyajyoti College, Delhi",
},{
  id:12,
  url:"https://www.youtube.com/embed/mOr-1cOwhb4",
  title:"Farewell 2014-2015 Batch - Vidyajyoti College of Theology, Delhi",
},{
  id:13,
  url:"https://www.youtube.com/embed/RUtFV04NW7Y",
  title:"Mission Possible Encountering God",
},{
  id:14,
  url:"https://www.youtube.com/embed/iiBuGvnopBs",
  title:"Vidya Jyoti College of Theology - Farewell of 2016-17 Batch",
},{
  id:15,
  url:"https://www.youtube.com/embed/_OiqqBlnKVg",
  title:"Diaconate Ordination 2020 || Vidyajyoti College of Theology, Delhi",
},{
  id:16,
  url:"https://www.youtube.com/embed/jgZg3NvmvAI",
  title:"Stand with Stan || Vidyajyoti College of Theology",
},{
  id:17,
  url:"https://www.youtube.com/embed/9pYPh22hgQk",
  title:"Diaconate Ordination on 26th February 2022  3 pm @ Millennium Hall, St Xavier&#39;s School New Delhi",
},{
  id:18,
  url:"https://www.youtube.com/embed/J9ji0LAyxOk",
  title:"Balmela 2013",
}];

export default function YouTubeChannel() {
  const [videoDetails, setVideoDetails]=useState({title:'', url:''});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setVideoDetails({...videoDetails,
      url:playList[0].url,
      title:playList[0].title});
    const timer = setTimeout(() => setLoaded(true), 2000);
    setLoaded(true);
    return () => clearTimeout(timer); 
  }, [])
  

  const handleVideoView=(event, item)=>{
    //event.preventDefault();
    try{
     // console.log("item :", item);
      setVideoDetails({...videoDetails,
      title:item.title,
      url:item.url
      });
    }catch(error){
      console.log("error");
    }
  }

  return (
    <>
    <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

    <Preloader show={loaded ? false : true} />
    {/*<PublicationHeader />
    <FaceView />
    <NewFaceView /> */}
    <div className='container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>Vidyajyoti YouTube Channel</h1>
        <p>CHANNEL LINK - https://www.youtube.com/channel/UCdNmmekVJMKCbO2lISZNCWw</p>
        <div id="videoView" className="container py-5" style={{textAlign:"center"}}>
          <iframe width="848" height="476" src={videoDetails.url} title={videoDetails.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className="row g-5">
        {playList.map((item, index)=>
        <div key={index} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
        <div className="project-item">
            <div className="project-img">
                {/*<img src={item.poster} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>*/}
                <iframe className="img-fluid w-100 rounded" style={{height:"250px"}} src={item.url} title={item.title} frameborder="0" allow=" picture-in-picture;" ></iframe>
                <div className="project-content">
                    <a href="#videoView" className="text-center" onClick={event=>{handleVideoView(event, item)}}>
                        <h4 className="text-secondary"><i className="bi bi-youtube fa-7x mb-4"></i></h4>
                    </a>
                </div>
            </div>
        </div>
    </div>  
        )}
        </div>


        {/*<div className="entry-content">
    		<h2><span style={{color: "#993366", textDecoration: "underline"}}><strong>CHANNEL LINK</strong></span></h2>
            <p><a href="https://www.youtube.com/channel/UCdNmmekVJMKCbO2lISZNCWw" target="_blank" rel="noopener noreferrer">https://www.youtube.com/channel/UCdNmmekVJMKCbO2lISZNCWw</a></p>

            <div className="epyt-gallery" data-currpage="1" id="epyt_gallery_21654">
            <div className="epyt-video-wrapper">
            <iframe  id="_ytid_83987"  width="895" height="503"  data-origwidth="895" data-origheight="503"  data-relstop="1" src="https://www.youtube.com/embed/tbB_ZFyk3Ns?enablejsapi=1&autoplay=0&cc_load_policy=0&cc_lang_pref=&iv_load_policy=1&loop=0&modestbranding=1&rel=0&fs=1&playsinline=0&autohide=2&theme=dark&color=red&controls=1&" className="__youtube_prefs__  no-lazyload" title="YouTube player"  data-epytgalleryid="epyt_gallery_21654"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen data-no-lazy="1" data-skipgform_ajax_framebjll="">
            </iframe>
            </div>
            <div className="epyt-gallery-list"> 
            <div className="epyt-pagination ">
            <div tabindex="0" role="button" className="epyt-pagebutton epyt-prev  hide " data-playlistid="UUdNmmekVJMKCbO2lISZNCWw" data-pagesize="15" data-pagetoken="" data-epcolumns="3" data-showtitle="1" data-showpaging="1" data-autonext="0" data-thumbplay="1">
            <div className="epyt-arrow">
            </div> 
            <div>Prev</div>
            </div>
            <div className="epyt-pagenumbers ">
            <div className="epyt-current">1</div>
            <div className="epyt-pageseparator"> / </div>
            <div className="epyt-totalpages">2</div></div>
            <div tabindex="0" role="button" className="epyt-pagebutton epyt-next" data-playlistid="UUdNmmekVJMKCbO2lISZNCWw" data-pagesize="15" data-pagetoken="EAAaIVBUOkNBOGlFRVJCUVRVMU1VTkdOekF3T0RRMFF6TW9BUQ" data-epcolumns="3" data-showtitle="1" data-showpaging="1" data-autonext="0" data-thumbplay="1"><div>Next</div> 
            <div className="epyt-arrow">&raquo;</div></div>
            <div className="epyt-loader">
            <img alt="loading" width="16" height="11" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/gallery-page-loader.gif"/></div>
            </div>
            <div className="epyt-gallery-allthumbs  epyt-cols-3 ">
            <div tabindex="0" role="button" data-videoid="tbB_ZFyk3Ns" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style={{backgroundImage: "url(https://i.ytimg.com/vi/tbB_ZFyk3Ns/hqdefault.jpg)"}}>
            <div className="epyt-gallery-playhover">
            <img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" />
            <div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title" style={{width:"80px", height:"100px"}}>VJ2022  Farewell 19/03/2022</div></div><div tabindex="0" role="button" data-videoid="fZX18rE327A" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style={{backgroundImage: "url(https://i.ytimg.com/vi/fZX18rE327A/hqdefault.jpg)"}}>
            <div className="epyt-gallery-playhover">
            <img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" />
            <div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Inaugural Mass Vidya Jyoti College - New Delhi 10.08.2020</div></div><div tabindex="0" role="button" data-videoid="BmTR_zL_tZo" className="epyt-gallery-thumb">
            <div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style={{backgroundImage: "url(https://i.ytimg.com/vi/BmTR_zL_tZo/hqdefault.jpg)"}}><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" />
            <div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Karwani Muhabat 2017</div></div><div className="epyt-gallery-rowbreak"></div><div tabindex="0" role="button" data-videoid="5CTMn2W7Dkg" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style={{backgroundImage: "url(https://i.ytimg.com/vi/5CTMn2W7Dkg/hqdefault.jpg)"}}><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            {/*<div className="epyt-gallery-title">Ministry Presentation 2016</div></div><div tabindex="0" role="button" data-videoid="4hLz9Y67NU8" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/4hLz9Y67NU8/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Asha  2016</div></div><div tabindex="0" role="button" data-videoid="im9gcaHZqI4" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/im9gcaHZqI4/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Balmela - 2015</div></div><div className="epyt-gallery-rowbreak"></div><div tabindex="0" role="button" data-videoid="zZ2Wd9gd-Ug" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/zZ2Wd9gd-Ug/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Diaconate 2016</div></div><div tabindex="0" role="button" data-videoid="44TOjoE9vfg" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/44TOjoE9vfg/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Theological Drama</div></div><div tabindex="0" role="button" data-videoid="39iuJK34MyI" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/39iuJK34MyI/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Together along the way</div></div><div className="epyt-gallery-rowbreak"></div><div tabindex="0" role="button" data-videoid="Ik7IG30en_s" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/Ik7IG30en_s/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Theological Drama</div></div><div tabindex="0" role="button" data-videoid="5OkYkS8sIQc" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/5OkYkS8sIQc/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Diaconate Ordination - 2017 || Vidyajyoti College, Delhi</div></div><div tabindex="0" role="button" data-videoid="mOr-1cOwhb4" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/mOr-1cOwhb4/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Farewell 2014-2015 Batch - Vidyajyoti College of Theology, Delhi</div></div><div className="epyt-gallery-rowbreak"></div><div tabindex="0" role="button" data-videoid="RUtFV04NW7Y" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/RUtFV04NW7Y/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
            <div className="epyt-gallery-title">Mission Possible Encountering God</div></div><div tabindex="0" role="button" data-videoid="iiBuGvnopBs" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/iiBuGvnopBs/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>
  <div className="epyt-gallery-title">Vidya Jyoti College of Theology - Farewell of 2016-17 Batch</div></div><div tabindex="0" role="button" data-videoid="_OiqqBlnKVg" className="epyt-gallery-thumb"><div className="epyt-gallery-img-box"><div className="epyt-gallery-img" style="background-image: url(https://i.ytimg.com/vi/_OiqqBlnKVg/hqdefault.jpg)"><div className="epyt-gallery-playhover"><img alt="play" className="epyt-play-img" width="30" height="23" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/playhover.png" data-no-lazy="1" data-skipgform_ajax_framebjll="" /><div className="epyt-gallery-playcrutch"></div></div></div></div>*/}
           {/*} <div className="epyt-gallery-title">Diaconate Ordination 2020 || Vidyajyoti College of Theology, Delhi</div></div><div className="epyt-gallery-rowbreak"></div><div className="epyt-gallery-clear"></div></div><div className="epyt-pagination "><div tabindex="0" role="button" className="epyt-pagebutton epyt-prev  hide " data-playlistid="UUdNmmekVJMKCbO2lISZNCWw" data-pagesize="15" data-pagetoken="" data-epcolumns="3" data-showtitle="1" data-showpaging="1" data-autonext="0" data-thumbplay="1"><div className="epyt-arrow">&laquo;</div> <div>Prev</div></div><div className="epyt-pagenumbers "><div className="epyt-current">1</div><div className="epyt-pageseparator"> / </div><div className="epyt-totalpages">2</div></div><div tabindex="0" role="button" className="epyt-pagebutton epyt-next" data-playlistid="UUdNmmekVJMKCbO2lISZNCWw" data-pagesize="15" data-pagetoken="EAAaIVBUOkNBOGlFRVJCUVRVMU1VTkdOekF3T0RRMFF6TW9BUQ" data-epcolumns="3" data-showtitle="1" data-showpaging="1" data-autonext="0" data-thumbplay="1"><div>Next</div> <div className="epyt-arrow">&raquo;</div></div><div className="epyt-loader"><img alt="loading" width="16" height="11" src="https://vidyajyoticollege.in/wp-content/plugins/youtube-embed-plus/images/gallery-page-loader.gif" /></div></div></div></div>
			</div>*/}



    {/*<Player>
      <source src="https://www.youtube.com/embed/tbB_ZFyk3Ns" />
</Player>*/}

    </div>
    </>
  )
}
