import React, {useEffect} from 'react';
//import "../../lib/owlcarousel/assets/owl.carousel.css";
import $ from "jquery";
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel';
//import OwlCarousel from 'react-owl-carousel';  
//import 'owl.carousel/dist/assets/owl.carousel.css';  
//import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { officialsList } from '../../ProData';
//import defaultAvatar from '../../assests/images/photos/priestAvatar2.png';


export default function TeamOfficials() {

  {/*}  useEffect(() => {
        $(window).on(function(){
            $(".owl-carousel").owlCarousel();
          });
              
    }, [])*/}
    

  return (
    <div className='' style={{color:"black"}}>
        <div className="container-fluid  team">
            <div className="container py-5">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary">Our Team</h5>
                    <h1 className="mb-3 text-dark-main">Academic Authorities</h1>
                </div>
              
                <div className="row g-4" >
        {officialsList.map((item, index)=>
                    <div key={index} className=" col-lg-4 col-xl-4 wow fadeIn d-flex justify-content-center" data-wow-delay=".3s">
           <div className="rounded team-item" style={{height:"430px", width:"280px"}}>
           <div className="team-content">
               <div className="team-img-icon">
                   <div className="team-img rounded-circle">
                       <img src={item.avatarSrc} className="img-fluid w-100 rounded-circle" style={{height:"200px", maxHeight:"200px"}} alt="Avatar"/>
                   </div>
                   <div className="team-name text-center py-3">
                       <h4 className="">{item.name}</h4>
                       <p className="m-0">{item.title}</p>
                       <p className="m-0">{item.subTitle}</p>
                   </div>
               </div>
           </div>
       </div>
       </div>

        )}
        </div>
            </div>
        </div>
    </div>
  )
}
