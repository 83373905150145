import React from 'react';
//import TeamHeader from './TeamHeader'
//import NewFaceView from '../NewFaceView'
import TeamOfficials from './TeamOfficials';
import {Helmet} from "react-helmet-async";

export default function TeamOfficialMain() {
  return (
    <div>
    <Helmet>
      <title>Officials - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Officials - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Officials - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/about/officials" />
    </Helmet>

       {/*  <TeamHeader />
       <AboutUsFace />
        <NewFaceView />*/}
        <TeamOfficials />
    </div>
  )
}
