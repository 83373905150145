import React from 'react'
//import ContactHeader from './ContactHeader'
import ContactContent from './ContactContent'
//import FaceView from '../FaceView'
//import NewFaceView from '../NewFaceView'
//import VidyaJyotiBanner from '../VidyaJyotiBanner'
import {Helmet} from "react-helmet-async";

export default function ContactMain() {
  return (
    <div>
    <Helmet>
      <title>Contact Us - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Contact Us - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Contact US - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/contact" />
    </Helmet>

        {/*<ContactHeader />
        <FaceView />
        <NewFaceView />*/}
        <ContactContent />
    </div>
  )
}
