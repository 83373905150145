import React from "react";
//import Spinner from "../../../images/spinner.gif";
import logo from '../../assests/images/logo/VJ-College-logo.png';
import './PageLoader.css';
const FullPageLoader = () => {
    return (
        <div className="custom-loader">
        <section className="preloaderidntica">
		<div className="spinner">
		</div>
		<span><img src={logo} alt="" style={{width: "60px", height:"60px"}}/></span>
        </section>  
        </div>
    );
};
export default FullPageLoader;