import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import {NavbarBrand,Row, Col, NavbarToggler, Nav, NavItem, Collapse, UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu  } from "reactstrap";
import Topbar from './Topbar';
import vjtr from '../lib/img/1vjtr.jpg';
import newgif from "../assests/images/new-icon-gif-9.gif";
import ihs from "../assests/images/ihs.png";
//import newgif1 from "../assests/images/newgiphy.gif";

//import ave from "../lib/img/ave.jpg"
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { newlist } from '../ProData';


export default function Navbar() {
    const [isOpen, setIsOpen]= useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);
    const [newDisplay, setNewDisplay] = useState([]);
    const imgProps={
        0: {
            items: 1,
        },
        1024: {
            items: 2,
            itemsFit: 'contain',
        }
      };
    
    const  toggleOpen = () => setIsOpen({ isOpen: !isOpen });

    useEffect(()=>{
        processData();
    },[])

    const handleDragStart = (e) => e.preventDefault();

    const processData =async()=>{
        try{
            const finalList=[];
            const finalList1=[];
            if(newlist.length>0){
              for (const i in newlist){
                //const tempValue={};
                //tempValue.fullPathName=newlist[i];
                //tempValue.fileName=tBreakUp;
               // const tdoctorName=tBreakUp[1];
               // const tempDName=tdoctorName.split(".");
               // tempValue.doctorName=tempDName[0];
               // console.log("tempvalue : ", tempValue);
               // tempValue.projectSize=tBreakUp[2];
                //tempValue.projectDate=tBreakUp[3];
                const temp=<img src={newlist[i].imgSrc} onDragStart={handleDragStart} role='presentation' key={i} alt={newlist[i].title} style={{padding:"5px", borderRadius:"20px"}}/>
                finalList1.push(temp);
                //finalList.push(tempValue);
              }
            }      
            setNewDisplay(finalList1);
    }catch(error){
    }
    }


  return (
<div > 
    <div >
        <nav className="navbar  navbar-dark py-0">
            <Row className="navbar-main d-flex justify-content-center p-2" >
                <Col className='d-flex justify-content-center '>
                 <a href="https://vidyajyoticollege.in" className="navbar-brand " style={{paddingRight:"5%"}}><img src="/img/VJ-College-logo.png" className='img-fluid' style={{marginLeft:"30px" }} alt="College Logo"/>
            </a>
                <span>
               <p className='text-dark-main'> <b>Vidyajyoti - Institute of Religious Studies - College of Theology </b><br /></p>
               <Topbar/>
               </span>
               
               <div style={{paddingLeft:"5%" }}>
              {/* 
               <img src={newgif} alt="" className='img-fluid'style={{margin:"auto", width:"20%"}} />

              } <div className="container-fluid px-0">
            <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner" role="listbox">
                   <AliceCarousel mouseTracking items={newDisplay}  
                    autoPlay infinite,  
                    responsive={imgProps}
                    animationDuration={1200}
                    animationType="fadeout"
                    autoPlayDirection="rtl"
                    disableButtonsControls={true}
                 
                    />  
                </div>
            </div>
        </div>*/}
               <img src={ihs} alt="" className='img-fluid' style={{margin:"auto", width:"100%", borderRadius:"20px"}} />
               </div>

               {/*<img src={ave} alt="" className='img-fluid'style={{margin:"auto"}} />*/}
              </Col>
            </Row>
            {/*<Row className="navbar-mobile ">
                <Col className='d-flex '  >
                 <a href="https://vidyajyoticollege.in" className="navbar-brand"><img src="/img/VJ-College-logo.png" className='img-fluid' style={{width:"2rem", height:"2rem"}} alt="College Logo"/>
            </a>
               <span>
               <p className='text-dark-main' style={{paddingTop:"3%", fontSize:"18px"}}>Vidyajyoti College of Theology, Delhi  </p>
               </span>
                </Col>
            </Row>     */}     
        </nav>
    </div>
    <div className="bg-dark-main container-fluid py-2 text-center" >
    <nav className="navbar  navbar-dark navbar-expand-lg py-0">
            <div className="navbar-mobile ">
            <span className='d-flex justify-content-center'> 
            <a href="https://vidyajyoticollege.in" className="navbar-brand"><img src="/img/VJ-College-logo.png" className='img-fluid' style={{width:"2rem", height:"2rem"}} alt="College Logo"/>
            </a>
               <h3 style={{color:"gold", fontSize:"18px", fontWeight:"800px", paddingTop:"0px"}}>Vidyajyoti College of Theology</h3>
               <NavbarToggler onClick={toggleNavbar} /> 
               </span>
            </div>
            <Collapse isOpen={!collapsed} navbar  >     
                <Nav navbar className="navbar-nav ms-auto mx-xl-auto p-0 justify-content-center">
                <Link to={`/`} onClick={toggleNavbar}><a href="#" className="nav-item nav-link  ">Home</a></Link>
                <div className="nav-item dropdown" >
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" >About</a>
                        <div className="dropdown-menu rounded" >
                            <Link to={`/about/vision-mission`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Vision & Mission</a></Link>
                            <Link to={`/about/history`} onClick={toggleNavbar}> <a href="#" className="dropdown-item">History of VJ</a></Link>
                            <Link to={`/about/Officials`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Academic Authorities</a></Link>
                            <Link to={`/about/principalmessage`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Principal's Message</a></Link>
                            <Link to={`/`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Rector's Message</a></Link>
                            <Link to={`/about/formation`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Formation</a></Link>
                            <Link to={`/about/sjinfo`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Society of Jesus</a></Link>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Faculty</a>
                        <div className="dropdown-menu rounded">
                            <Link to={`/about/residentialFaculty`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Residential Faculty</a></Link>
                            <Link to={`/about/visitingFaculty`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Visiting Faculty</a></Link>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Academics</a>
                        <div className="dropdown-menu rounded">
                            <Link to={`/academics/methodolgoy`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Methodology</a></Link>
                            <Link to={`/academics/bachealor`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Bachelors (BTH)</a></Link>
                            <Link to={`/academics/master`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Master (MTH)</a></Link>
                            <Link to={`/academics/doctoral`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Doctoral Studies</a></Link>
                            <Link to={`/academics/diploma`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Diploma Program</a></Link>
                            <Link to={`/academics/certificate`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Certificate Program</a></Link>
                            <Link to={`/academics/depth`} onClick={toggleNavbar}><a href="#" className="dropdown-item">DEPTh</a></Link>
                            <Link to={`/academics/theology/bachealor`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Fellow Institutions</a></Link>
                
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Students</a>
                        <div className="dropdown-menu rounded">
                            <a href="https://students.vidyajyoticollege.in"  target="_blank" className="dropdown-item">Student Portal</a>
                            {/*<Link to={`/`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Batches</a></Link>*/}
                            <Link to={`/students/fieldwork`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Field Work</a></Link>
                            <Link to={`/`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Achievements</a></Link>               
                        </div>
                    </div>
                {/*<Link to={`/service`}><a href="#" className="nav-item nav-link text-secondary">Services</a></Link>*/}
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Admission</a>
                        <div className="dropdown-menu rounded">
                            <Link to={`/admission/details`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Admission Details</a></Link>
                            <Link to={`/admission/application-form`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Online Application</a></Link>
                            <Link to={`/admission/application-status`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Application Status</a></Link>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Library</a>
                        <div className="dropdown-menu rounded">
                            <a href="https://library.vidyajyoticollege.in"  target="_blank" className="dropdown-item">Library Portal</a>
                            <Link to={`/library/about`} onClick={toggleNavbar}><a href="#" className="dropdown-item">About Library</a></Link>
                            <Link to={`/library/latest`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Latest Arrivals</a></Link>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Gallery</a>
                        <div className="dropdown-menu rounded">
                            <Link to={`/`} onClick={toggleNavbar}><a href="#" className="dropdown-item">News & Events</a></Link>
                            <Link to={`/gallery/photo`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Photos</a></Link>
                            <Link to={`/gallery/video`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Videos</a></Link>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</a>
                        <div className="dropdown-menu rounded">
                            <Link to={`/services/accomodation`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Accomodation</a></Link>
                            <Link to={`/`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Chapel</a></Link>
                            <a href="https://adminoffice.vidyajyoticollege.in"  target="_blank" className="dropdown-item">Admin Office</a>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Publications</a>
                        <div className="dropdown-menu rounded">
                            <Link to={`/publications/youtube`} onClick={toggleNavbar}><a href="#" className="dropdown-item">YouTube Channel</a></Link>
                            <Link to={`/publications/vj-journal`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Vidyajyoti Journal</a></Link>
                            <Link to={`/publications/ave`} onClick={toggleNavbar}><a href="#" className="dropdown-item">AVE Reflection</a></Link>
                            <Link to={`/publications/vjtoday`} onClick={toggleNavbar}><a href="#" className="dropdown-item">VJ Today</a></Link>
                            <Link to={`/publications/tattvaviveka`} onClick={toggleNavbar}><a href="#" className="dropdown-item">Tattvaviveka</a></Link>
                        </div>
                    </div>
                   <Link to={`/academics/depth`} onClick={toggleNavbar}><a href="#" className="nav-item nav-link">DEPTh</a></Link>
                    <Link to={`/contact`} onClick={toggleNavbar}><a href="#" className="nav-item nav-link">Contact</a></Link>
                    </Nav>
                  </Collapse>
                  </nav>
    </div>
</div>
)
}
