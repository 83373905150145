import React from 'react';
import Services from '../Services';
//import ServiceHeader from './ServiceHeader'
//import AboutUsFace from '../about/AboutUsFace'
import PublicationHeader from '../publications/PublicationHeader';
//import NewFaceView from '../NewFaceView'
import {Helmet} from "react-helmet-async";

export default function ServiceMain() {
  return (
    <div>
    <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

        <PublicationHeader />
       {/*} <AboutUsFace />
       <NewFaceView />*/}
        <Services />

    </div>
  )
}
