import React from 'react';
//import PublicationHeader from './PublicationHeader';
//import FaceView from '../FaceView';
import {Helmet} from "react-helmet-async";

export default function VJJournal() {
  return (
    <>
    <Helmet>
      <title>Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Publications - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/publications" />
    </Helmet>

    {/*<PublicationHeader />
    <FaceView />*/}
    <div className='sec container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>Vidyajyoti Journal</h1>
        <h5 className="text-primary" style={{textAlign:"center"}}>VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION (VJTR)</h5>
        <p style={{textAlign:'left', lineHeight:'30px'}} >VJTR is published 12 times a year from the Vidyajyoti College of Theology, Delhi, India by Vidyajyoti Educational and Welfare Society (VIEWS). The College and the Journal are both works of the Society of Jesus. Begun with the title The Clergy Monthly in 1938, the Journal assumed its present title in 1975.</p>
        <p style={{textAlign:'left', lineHeight:'30px'}}>This monthly publication reaches nearly 4000 subscribers all over the world, including most theological colleges of English-speaking countries. Its main scope is to foster reflection on social and cultural issues in South Asia and elsewhere in the light of the Christian faith and other religious and philosophical traditions. It covers areas of Christian theology and service, interreligious dialogue, Indian theology, and social questions significant for the Christian faith and society today.</p>
        <p style={{textAlign:'left', lineHeight:'30px'}}>VIDYAJYOTI JOURNAL seeks to promote Indian ways of Christian witnessing and theologizing. It is principally, not exclusively, at the service of all who exercise responsibility and leadership in the Christian community – Clergy, Religious and Laity. It mediates to them the ministry of Indian theologians and theologians from other nations.</p>
        <p style={{textAlign:'left', lineHeight:'30px'}}>The Journal respects the creative freedom of theological and pastoral research within the parameters of the Gospel and of the Christian faith. Published from within the rich and diverse perspectives of the Catholic tradition, it remains open to other views in a spirit of dialogue and search for deeper truth, wherever it is found. The different points of view expressed in the Journal may not necessarily agree with that of the Editorial Board or the Faculty of Vidyajyoti College of Theology.</p>
    </div>
    </>
  )
}
