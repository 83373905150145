import React, { Component } from 'react';
import {Helmet} from "react-helmet-async";
import {Row, Col} from 'reactstrap';
import sjpic from '../../assests/img/jesuits.jpg';
import inigopic from '../../assests/img/igni.jpg';
import companianpic from '../../assests/img/first-companians.png'; 


export default class SocityHistory extends Component {
  render() {
    return (
      <div className='container sjinfo'>
<Helmet>
          <title>About Us - Society of Jesus</title>
          <meta name="description" content="Formation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
          <meta name="keywords" content="Formation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
          <link rel="canonical" href="/about/formation" />
        </Helmet>
        <h5 className="text-primary" style={{textAlign:"center"}}>About Us</h5>
            <h1 className="mb-2 p-2" style={{color:"#79031d", textAlign:"center"}}>History of the Society of Jesus</h1>
       <Row>
        <Col md={6} >
        <h5>Who We Are</h5>
        <p>The Society of Jesus was founded in 1540 by Saint Ignatius Loyola and his companions. The Jesuit mission is a mission of reconciliation, working so that women and men can be reconciled with God, with themselves, with each other and with God’s creation.</p>
                 <p  >  
                  
        <h5>Our History</h5>  
                    We are the Society of Jesus, a Roman Catholic order of priests and brothers founded half a millennium ago by the soldier-turned-mystic Ignatius Loyola. But most people call us “the Jesuits.”
                    </p>
        
                    <h5>Vission of St. Ignatius of Loyola</h5>
                    <p  >    
                    In the vision of our founder, we seek to “find God in all things.” We dedicate ourselves to the “greater glory of God” and the good of all humanity. And we do so gratefully in collaboration with others who share our values, including laypersons. They have become part of the “we,” the extended Jesuit family.
                    </p>
                    
        <h5>The Jesuits Today</h5>
                    <p  >    
                    With 16,000-plus priests, brothers, scholastics and novices worldwide, we are the largest male religious order in the Catholic Church. We are pastors, teachers, and chaplains. We are also doctors, lawyers, and astronomers, among many other roles in Church and society. In our varied ministries, we care for the whole person: body, mind, and soul. And especially in our education ministries, we seek to nurture “men and women for others.”
                    </p>
                    
        <h5>Ignatian Spirituality</h5>
                    <p  >    
                    Jesuits draw on the rich tradition of Ignatian spirituality and reflection. In our retreat centers, parishes, campus ministries, and other settings, we offer these resources to all who want to discern God’s presence in their lives. At the same time, we also aim to be “contemplatives in action,” people who bring this spirituality into the wide world. That includes our work on behalf of global justice, peace, and dialogue.
                    </p>
        </Col>
        <Col md={6} className='text-center'>
        <img className='img-fluid rounded m-3' src={inigopic} alt='' />
        <img className='img-fluid rounded m-3' src={companianpic} alt='' />
        <img className='img-fluid rounded m-3' src={sjpic} alt='' />
        
        
                </Col>
       </Row>
   
               
      </div>
    )
  }
}
