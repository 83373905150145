import React from 'react';
import {Helmet} from "react-helmet-async";
import { fieldWorkList } from '../../ProData';


export default function StudentFieldWork() {
  return (
    <div>
         <div className='sec'>
    <Helmet>
      <title>Student Field Work - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Student Field Work - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Student Field Work - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/students/fieldwork" />
    </Helmet>

    {/*<AcademicsHeader />
    <NewFaceView />*/}
    <div className='container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>Field Work and Pastoral Reflection</h1>
        <p style={{color:"black", textAlign:'justify'}}>Active apostolic involvement (field work) throughout the year is an integral part of theological formation in Vidyajyoti. The aims of field work are: formation in personal attitudes and skills, initiation to theological reflection on praxis and the habit of guided self- evaluation of one’s pastoral involvement as a means of growth.</p>
        <h5 className="text-primary">The present areas of fieldwork are the following:</h5>
        <div className="row g-5">
                    {fieldWorkList.map((item,index)=>{return(
                    <div key={index} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={item.imageSrc} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">{item.field}</h4>
                                       {/*} <p className="m-0 text-white">Majnu Ka Tila</p>
                                        <p className="m-0 text-white">Jangpura</p>
                    <p className="m-0 text-white">Seemapuri</p>*/}

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>)})}
                </div>
        <p style={{color:"black", textAlign:'justify'}}>One of these or other approved areas of fieldwork should be chosen by every student. New types of fieldwork could be started by a group of students only in consultation with the Director of Fieldwork. Pastoral reflection on fieldwork will take place at least once a month.</p>
    </div>
    </div>
 
    </div>
  )
}
