import React from 'react';
import history from '../../assests/img/vjhist.jpg';
import history2 from '../../assests/img/VJ-Jubilee.jpg';
//import NewFaceView from '../NewFaceView';
//import AboutUsHeader from './AboutUsHeader';
import {Helmet} from "react-helmet-async";

export default function History() {
  return (
    <div className='sec'>
    <Helmet>
      <title>History - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="History - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="History - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/about/history" />
    </Helmet>

        {/*<AboutUsHeader />
        <NewFaceView />*/}
        <div className='sec vission-mission'>
 <div className="container-fluid py-5 my-5">
        <h5 className="text-primary" style={{textAlign:"center"}}>About Us</h5>
            <h1 className="mb-4" style={{textAlign:"center"}}>History</h1>
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s" style={{textAlign:"center"}}>
                        <div className="h-100 position-relative" style={{width: "100%"}} >
                            <img src={history2} className="imgrotation img-fluid w-100 rounded" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                        <p style={{  textAlign:"justify", lineHeight:"35px"}}>
                        <b>Vidyajyoti</b> has a long history of about 150+ years. It was founded as a ‘Seminary’ in 1879 in Asansol, and was transferred in 1889 to Kurseong, Darjeeling District, where it took the name of St. Mary’s College. With the French Jesuits of Madurai Mission opening Sacred Heart College at Shembaganur in 1898 for the students of philosophy, Kurseong became exclusively a  Jesuit Theologate. In the year 1900 there were 29 scholastics, of whom 11 were Indians, mostly from Calicut and Madurai Missions.</p>   
                    </div>
                    <p style={{  textAlign:"justify", lineHeight:"35px"}}>
                        In 1932 the Holy See officially erected it as a faculty, for members of the Society of Jesus, with authority to confer academic degrees in Theology.  Theological and missiological reasons (i.e. to be among the people, especially after Vatican II and in the light of the emergence of liberation theology) urged the Jesuit theologians and provincials to reflect on the transfer of theologate from the secluded beautiful Himalayas to people centered place in the plains. </p>
                   
                    <p style={{  textAlign:"justify", lineHeight:"35px"}}>
                    In 1932 the Holy See officially erected it as a faculty, for members of the Society of Jesus, with authority to confer academic degrees in Theology.  Theological and missiological reasons (i.e. to be among the people, especially after Vatican II and in the light of the emergence of liberation theology) urged the Jesuit theologians and provincials to reflect on the transfer of theologate from the secluded beautiful Himalayas to people centered place in the plains. 
                    </p>
                    <p style={{  textAlign:"justify", lineHeight:"35px"}}>
                    Fr Pedro Arrupe, the then Superior General of the Society of Jesus, after a visit to Delhi in 1970, decided to transfer St Mary’s of Kurseong to Delhi.  Thus, St Mary College was shifted in the winter of 1971-72 to Delhi and classes began on17th January 1972. Vidyajyoti, Institute of Religious Studies had 62 theologians and 13 professors. Transporting the Library's 70,000 volumes, a Herculean task, was carried out efficiently.  In the meanwhile, upon a request from Fr. General, the Provincial of Patna very kindly agreed to place a part of St Xavier’s School at the disposal of the theologate. Rev.Fr  Pedro Arrupe blessed the academic block in 1977 and the VJ Residence was ready for use by 1979.</p>
                   
                    <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
                    <p style={{  textAlign:"justify", lineHeight:"35px"}}>
                    Since 1932 St Mary’s College, as a ‘reserved faculty,’ has been training only the Jesuits but after 1968 Vatican allowed it to admit any other duly qualified student.  Consequently, from 1974, seminarians of Delhi Archdiocese and of other dioceses as well as the Religious began to enroll themselves. First woman student entered the campus in 1978.  A discerning participation in diverse educational, cultural and social programmes and events of the city is part of the overall formation which Vidyajyoti seeks to promote. With the same end in view speakers are invited to enlighten the students and staff on a broad range of topics. The training given to the students aims at the integral formation of men and women for the selfless service of the people, especially the weaker sections of society irrespective of caste, colour or creed.
                    </p> </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s" style={{textAlign:"center"}}>
                        <div className="h-100 position-relative" style={{width: "100%"}} >
                            <img src={history} className="imgrotation img-fluid w-100 rounded" alt=""/>
                        </div>
                    </div>
                    <p style={{  textAlign:"justify", lineHeight:"35px"}}>    
                    In December 1973 the Holy See approved experimentally the new Statutes of the Faculty and empowered it to confer its degrees on any properly qualified student.   In 1989 the definitive STATUTES were approved and ratified.  In the light of Veritatis Gaudium, Vidyajyoti revisited its own Statues and updated it.  It was approved and ratified by the Congregatio De Institutione Catholica in Rome, in 2022. 
                    </p>
                </div>
            </div>
        </div>
        </div>
       
    </div>
  )
}
