import React from 'react';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
//import GalleryHeader from './GalleryHeader';
//import FaceView from '../FaceView';
import {Helmet} from "react-helmet-async";

export default function VideoGallery() {
  return (
    <>
    <Helmet>
      <title>Video Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Video Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Video Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/gallery/video" />
    </Helmet>

    {/*<GalleryHeader />
    <FaceView />*/}
    <h5 className="text-primary" style={{textAlign:"center"}}>Video Gallery</h5>
    <Player>
      <source src="https://s3.ap-south-1.amazonaws.com/images.vidyajyoticollege.in/videos/gallery/TCT.mp4" />
    </Player>

    </>
  )
}
