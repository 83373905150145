import React,{useState, useEffect} from 'react';
import ImageGallery from "react-image-gallery";
import axios from 'axios';
//import GalleryHeader from './GalleryHeader';
//import FaceView from '../FaceView';
import Preloader from '../../components/Preloader/Preloader';
//import PhotoGallery1 from './PhotoGallery1';
//import PhotoGallery2 from './PhotoGallery2';
import "react-image-gallery/styles/css/image-gallery.css";
//import NewFaceView from '../NewFaceView';
import {Helmet} from "react-helmet-async";

const config = require('../../config.json');

/*const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
      originalTitle:"Test1",
      description:"desc1"
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
      originalTitle:"Test2",
      description:"desc2"
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
      originalTitle:"Test3",
      description:"desc3"
    },
  ];*/
  

export default function PhotoGallery() {

  const [galleryImages, setGalleryImages]=useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => { fetchGalleryImages();
    const timer = setTimeout(() => setLoaded(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  const fetchGalleryImages =async()=>{
    try{
       // console.log("Testing");
      //  const session = await Auth.currentSession();
      //  const access_token=session.idToken.jwtToken;
        const fileName='images/gallery/';
        const bucketName="images.vidyajyoticollege.in";
//      await axios.get(`${config.api.invokeUrl}/images/projects?fileName=${fileName}&bucketName=${bucketName}`,{
      await axios.get(`${config.api.invokeURL}/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
        headers: {
         // Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log(response.data);
        //setGalleryImages(response.data);
         processGalleryData(response.data);
        //setDispMsg(response.data.length + " - Images are found in your Gallery");
    }).catch((error)=>{
        console.log("Error:",error.message);
        //setDispMsg(error.message);
    })
}catch(error){
console.log(error.message);
    //setDispMsg(error.message);
}
  }

  const processGalleryData=(item)=>{
    const tempitem=item;
    //console.log("file list : ", item[0].fileName);
    for(const list in item){
          //console.log("file list : ", list[0].fileName);
          var fileList=[]; 
    for(const i in item[list].fileName){
      var tempurl1="";
      const tempgallery={
        thumbnail:"",
        original:""
      };
      //tempurl1.concat("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/");
      //tempurl1.concat(item[list].fileName[i]);
      tempurl1="https://s3.ap-south-1.amazonaws.com/images.vidyajyoticollege.in/"+item[list].fileName[i];
      //console.log("tempurl : ", tempurl1);
      tempgallery.thumbnail=tempurl1;
      tempgallery.original=tempurl1;
      fileList.push(tempgallery);
    }
    tempitem[list].fileName=fileList
    //console.log("temp item : ", tempitem);
  }
  setGalleryImages(tempitem);
  setLoaded(true);
  }


  return (
    <div>
    <Helmet>
      <title>Photo Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Photo Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Photo Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/gallery/photo" />
    </Helmet>

      <Preloader show={loaded ? false : true} />
      {/*<GalleryHeader />
      <FaceView />
      <NewFaceView />*/}
        <h5 className="text-primary" style={{textAlign:"center"}}>Photo Gallery</h5>
        {/*<PhotoGallery2 />*/}
        <div className="contact-detail position-relative p-5">
       {/*} <div className="row g-5 justify-content-center">
        <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
          <ImageGallery items={images} autoPlay={true} />
        </div>
        <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
          <ImageGallery items={images} />
        </div>
        </div>*/}
    {loaded && galleryImages.length>0 && <div className="row g-5 justify-content-center">
    {galleryImages.map((item, index)=>
        <div key={index} className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
            <h1 className="mb-4" style={{textAlign:"center"}}>{item.projectName}</h1>
            <ImageGallery items={item.fileName} autoPlay={true} />
            <h5 className="text-primary" style={{textAlign:"center"}}>{item.projectDate}</h5>
      </div>
    )}
  </div>}
  </div>
  </div>
  )
}
