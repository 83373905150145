import React,{useState} from 'react';
import { ProgressBar } from '@react-pdf-viewer/core';
import {Viewer } from '@react-pdf-viewer/core'; // install this library
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import packageJson from '../../package.json';
const writingGif=require("./writing.gif");
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library

export default function PDFViewer(props) {
    const {pdfFile} = props;
    const [loaded, setLoaded] = useState(true);
    const [progressValue, setProgressValue] = useState(0);
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];


    
    const handlePageChange = (e) => {
        localStorage.setItem('current-page', `${e.currentPage}`);
      };
      
      const handleSwitchTheme = (theme) => {
        localStorage.setItem('current-theme', theme);
      };
      
    const handleDocumentLoad=(e)=>{
        setLoaded(true);
      }

      const transform = (slot) => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        //EnterFullScreen: () => <></>,
        //EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });

    /*const handleFetchPDF = async() => {
        //console.log("fetch : ", item);
        try{
                const fetchFile=item.id+".pdf";
                //console.log("fetch file :", fetchFile);
                const bucketName='docs.vidyajyoticollege.in/pdfs/vjtoday';
                //const session = await Auth.currentSession();
                //const access_token=session.idToken.jwtToken;
                await axios.get(`${config.api.invokeUrl}/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}`,{
                    headers: {
                    //Authorization: access_token,
                    'x-api-key': config.api.key
                    }}, 
                ).then(async(response)=>{
                    //console.log("Response : ", response);
                    setPdfFile(response.data.downloadURL);
                    setShowPDF(true); 
      
                }).catch((error)=>{
                    console.log("Error1 : ", error);
                    //setDispMsg("Error Occured - ", error.message);
                })  
                
        }catch(error){
            console.log("Error2 : ", error);
        }
      }*/
      
      
  return (
    <>
    {progressValue<100 ?<div  className='container justify-content-between'  style={{ width: '340px', top:"25%"}}>
              <p style={{ alignItems:'center'}}>Loading... Please Wait...</p>
                <ProgressBar progress={progressValue} />
                <img src={writingGif} alt="Writing Gif"/>
            </div>:null}

    {pdfFile && loaded && <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    <Viewer fileUrl={pdfFile}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}
          renderLoader={(percentages) => (
            setProgressValue(Math.round(percentages))
        )} />
      </Worker>}
    </>
  )
}
