import React from 'react'

export default function FieldWorkContent() {
  return (
    <div>
         <div className="container-fluid project py-5 my-5">
            <div className="container py-5">
                <div className="text-center mx-auto pb-3 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary">Our Field Work</h5>
                    <h1 className='text-dark-main'>Places We Visit</h1>
                </div>
                <p>Active apostolic involvement (field work) throughout the years is an integral part of theological formation in Vidyajyoti. The aims of field work are: formation in personal attitudes and skills, initiation to theological reflection on praxis and the habit of guided self-evaluation of one’s pastoral involvement as a means of growth. </p>
                <div className="row g-5">
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/hospital.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Hospitals (MC sisters)</h4>
                                        <p className="m-0 text-white">Majnu Ka Tila</p>
                                        <p className="m-0 text-white">Jangpura</p>
                                        <p className="m-0 text-white">Seemapuri</p>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/publicplace.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">MPS: Moving to Public Spaces</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/parish.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Parishes</h4>
                                        <p className="m-0 text-white">23 Parishes</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/streetChildren.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Street Children</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/rickshaw.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Rikshaw Pullers</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/slumChildren.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Urban Poor - 5 Slums</h4>
                                        <p className="m-0 text-white">Seelampur, Jahangirpuri E & H blocks</p>
                                        <p className="m-0 text-white">Model town & Majnu Ka Tila</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/TiharJail.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Prison Ministry</h4>
                                        <p className="m-0 text-white">Tihar Ashram (Jail)</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/tribalPeople.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Tribal Ministry</h4>
                                        <p className="m-0 text-white">8 places in Delhi</p>
                                        <p className="m-0 text-white">catering to more than 2000 people</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/neccod.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">NECCOD</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/jrs.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Jesuit Refugee Service</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div className="project-item">
                            <div className="project-img">
                                <img src={require("../../assests/img/poster/katkatha.jpeg")} className="img-fluid w-100 rounded" alt="" style={{height:"250px"}}/>
                                <div className="project-content">
                                    <a href="#" className="text-center">
                                        <h4 className="Ylight-text-combo">Kat-Katha</h4>
                                        <p className="m-0 text-white">(Sex workers)</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
