import React from 'react';
import {Table} from 'reactstrap';
import { optionalCoursesList } from '../../ProData';
import {Helmet} from "react-helmet-async";


export default function BThContent() {
  return (
 
    <div className='sec'>
    <Helmet>
      <title>Bachelor of Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Bachelor of Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Bachelor of Theology - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/academics/bachealor" />
    </Helmet>

    {/*<AcademicsHeader />
    <NewFaceView />*/}
    <div className='container'>
        <h1 className="mb-3" style={{textAlign:"center"}}>BACHELOR OF THEOLOGY (B.Th.)</h1>
        <p style={{color:"black", textAlign:'justify'}}>The first cycle which leads to the B.Th. degree is organised based on credits. A credit is a unit of teaching/learning experience equivalent to about 15 periods of class with the corresponding amount of personal work. The courses offered may be of one or more credits. A total of about 107 credits is required.</p>
        <div style={{color:"black", textAlign:'justify', lineHeight:'30px'}}>
        <h5 className="text-primary">Lectures and Examinations</h5>
        <p>Regular attendance at lectures is expected from students for all the courses unless the Principal has approved of other arrangements, such as a group study or a tutorial system. Even when such alternatives are offered students have to take the examinations prescribed for every term.</p>
        <p>The Final B.Th. Comprehensive examination consists of a sixty-minute viva-voce before a board of three examiners based on a programme of theological issues involved in an examination syllabus presented by the Faculty. With the approval of the Principal, a student may opt to use, instead of this syllabus, his or her own comprehensive paper covering all the major areas of theology, written under the supervision of a staff member.</p>
        <p>The students may opt to have, prior to the oral comprehensive examination, a three-hour written exercise on a theological topic given to him or her by the Faculty. In this case, the evaluation of this written paper would count for 40% of the mark of the comprehensive examination, but it would not dispense from the oral examination mentioned above, the result of which, however, would count for only 60% of the comprehensive examination mark.</p>
        </div>
        <div style={{color:"black", textAlign:'justify', lineHeight:'30px'}}>
        <h5 className="text-primary">A.	GENERAL INTRODUCTION</h5>
        <p>The Vidyajyoti B.Th. Syllabus is envisaged in three tiers corresponding to the three years of the study of Theology at Vidyajyoti. (The ‘Fourth Year’ required for the priesthood is to be taken care of by the respective Provinces/Congregations/Dioceses after the B.Th. Final Comprehensive Exams). Each of the three tiers has a specific focus spelt out by the theme. Each tier is further divided into “units” made up of various courses (of one or more credits each), and they develop a sub-theme or a further specification of the year’s theme. The purpose of the new syllabus is to enable the student to do theology in an integrated and holistic manner. The Indian/South Asian dimension is integral to all the aspects of the syllabus. The method of starting from the South Asian subaltern realities and groups and opening out to the macro realities of the world characterizes the process. Human sciences, including philosophy, are important. Apostolic/Fieldwork involvement is integral to the theological process. An experience of the negativities of life and contact and commitment to a specific community of the marginalized ones of society help us theologize better. In its intentionality, theology aims at a transformation of individuals and structures. Hence with the help of the human and revealed sciences we follow a method where life and the inherited faith meet and challenge each other. We reflect on our way of living our faith in our situation to trigger transformation through cognitive, attitudinal, and behavioural changes. Preferentially we concentrate our fieldwork on the “poor”, the marginalized and the downtrodden. In the massive rejection of the marginalized who form the majority of our people, we touch the deepest sin of the modern world. In this way, emphasis is given to the spirituality and the human growth of the persons in training.</p>
        <p>The central themes for the three years are as follows: Theme 1: God within History and the Response of Faiths Theme 2: God in Jesus Christ, Church and Sacraments Theme 3: Christians in the World</p>
        </div>
        <div style={{color:"black", textAlign:'justify', lineHeight:'30px'}}>
        <h5 className="text-primary">B.	GENERAL DYNAMICS</h5>
        <p>In the First Year (Theme 1) we reflect on our faith experience in context. A critical socio-cultural analysis in the light of the Christian vision enables us to situate the human quest for meaning and the response of the religions through a history of sin and suffering, evil and struggle. We highlight the plight of the poor – tribals, Dalits and women, in particular. In this context, we see how religious ‘revelations’ seek to evoke human ‘faith responses’ resulting in various anthropologies and theologies of history. We then proceed to the Christian vision of the cosmos manifest in the Reign of God ideal in the light of Scripture and Tradition.</p>
        <p>The exploration of the divine-human encounter leads us to the Second Year (Theme 2) where we enter deeply into God’s manifestation in Jesus Christ as evidenced in the Gospels and the life of the Church. We highlight the eschatological and universal mission of Jesus to save all with his special option for the poor and the oppressed which led him to his passion, death and resurrection by which we are redeemed. Jesus leads us to the awareness of and communion with the Father and the Spirit. We explore other manifestations of God in history and the working of God’s Word and God’s Spirit in other religions. This leads us to a further deepening and broadening of our knowledge of God’s plan for the world which we already introduced in the first year. Also, we study how the Church, the visible body of Christ’s disciples, must function as a servant and sacrament of God’s Kingdom. We try to understand the organic nature of this body – its being and becoming, its structures and sacraments, its mission and mediatory function that necessitate relationship with other religious, social and secular bodies.</p>
        <p>In the Third Year (Theme 3) we focus on the Christian Life and Mission in the World. As a community of disciples in the world we reflect upon ethical questions and global concerns that threaten life in its myriad forms. We concentrate on practical knowledge and skills and reflect on spirituality to deal with the issues and problems that confront us today. Despite various negativities, we see that there is hope in various life-affirming movements and in our Christian way of being and living, illumined as they are by the eschatology of the risen Lord.</p>
        <p> The focus on the Mission will lead us to an awareness of specific concerns and challenges of the local Churches, which are best encountered in more particular contexts, backed by the pastoral practice of the diaconate and priesthood.</p>
        </div>
        <div style={{color:"black", textAlign:'justify', lineHeight:'30px'}}>
        <h5 className="text-primary">OPTIONAL COURSES</h5>
        <p>Optional courses are normally evaluated. The following optional courses are offered this year</p>
        </div>
        <Table hover responsive striped>
        <thead>
            <tr>
            <th>#</th>
            <th>Courses</th>
            <th>Credits</th>
            </tr>
        </thead>
        <tbody>
            {optionalCoursesList.map((item, index)=><tr>
                <th scope="row">{index+1}</th>
                <td>{item.course}</td>
                <td>{item.credits}</td>
            </tr>)}
            {/*<tr>
                <th scope="row">2</th>
                <td>Book of Revelation</td>
                <td>1</td>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Canon Law for Religious</td>
                <td>1</td>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Letter to the Hebrews</td>
                <td>1</td>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Judaism</td>
                <td>1</td>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Sufism</td>
                <td>1</td>
            </tr>*/}
        </tbody>
        </Table>
    </div>
    </div>
  )
}
