import React, {useEffect} from 'react';
//import "../../lib/owlcarousel/assets/owl.carousel.css";
import $ from "jquery";
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel';
//import OwlCarousel from 'react-owl-carousel';  
//import 'owl.carousel/dist/assets/owl.carousel.css';  
//import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { visitingFacultyList } from '../../ProData';
import defaultAvatar from '../../assests/images/photos/priestAvatar2.png';


export default function TeamContent() {

   {/*} useEffect(() => {
        $(window).on(function(){
            $(".owl-carousel").owlCarousel();
          });
              
    }, [])*/}
    

  return (
    <div>
        <div className="container-fluid team">
            <div className="container py-5">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary"></h5>
                    <h1 className="mb-3 text-dark-main">Meet our Visiting Faculty</h1>
                </div>
                {/*</OwlCarousel>  */}
                <div className="row g-4 ">
        {visitingFacultyList.map((item, index)=>
                    <div key={index} className="col-lg-4 col-xl-3 wow fadeIn d-flex justify-content-center" data-wow-delay=".3s" >
           <div className="rounded team-item " style={{height:"400px", width:"280px"}}>
           <div className="team-content ">
               <div className="team-img-icon">
                   <div className="team-img rounded-circle">
                   <img src={item.avatarSrc} className="img-fluid w-100 rounded-circle" style={{height:"200px", maxHeight:"200px"}} alt="Avatar"/>
                   </div>
                   <div className="team-name text-center py-3">
                       <h4 className="">{item.name}</h4>
                       <p className="m-0">{item.subject}</p>
                      {/*} <p className="m-0">{item.email}</p>*/}
                   </div>
                   {/*<div className="team-icon d-flex justify-content-center pb-4">
                       <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                       <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                       <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                       <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
</div>*/}
               </div>
           </div>
       </div>
       </div>

        )}
        </div>

                
                {/*<div className="owl-carousel owl-theme team-carousel wow fadeIn" data-wow-delay=".5s">
                    <div className="rounded team-item">
                        <div className="team-content">
                            <div className="team-img-icon">
                                <div className="team-img rounded-circle">
                                    <img src="img/team-1.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                                </div>
                                <div className="team-name text-center py-3">
                                    <h4 className="">Full Name</h4>
                                    <p className="m-0">Designation</p>
                                </div>
                                <div className="team-icon d-flex justify-content-center pb-4">
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded team-item">
                        <div className="team-content">
                            <div className="team-img-icon">
                                <div className="team-img rounded-circle">
                                    <img src="img/team-2.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                                </div>
                                <div className="team-name text-center py-3">
                                    <h4 className="">Full Name</h4>
                                    <p className="m-0">Designation</p>
                                </div>
                                <div className="team-icon d-flex justify-content-center pb-4">
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded team-item">
                        <div className="team-content">
                            <div className="team-img-icon">
                                <div className="team-img rounded-circle">
                                    <img src="img/team-3.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                                </div>
                                <div className="team-name text-center py-3">
                                    <h4 className="">Full Name</h4>
                                    <p className="m-0">Designation</p>
                                </div>
                                <div className="team-icon d-flex justify-content-center pb-4">
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded team-item">
                        <div className="team-content">
                            <div className="team-img-icon">
                                <div className="team-img rounded-circle">
                                    <img src="img/team-4.jpg" className="img-fluid w-100 rounded-circle" alt=""/>
                                </div>
                                <div className="team-name text-center py-3">
                                    <h4 className="">Full Name</h4>
                                    <p className="m-0">Designation</p>
                                </div>
                                <div className="team-icon d-flex justify-content-center pb-4">
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href=""><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    </div>
  )
}
