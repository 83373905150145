import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import princi from '../../lib/img/rajkumarsj.jpg';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import imgvjgp from '../../assests/images/VJCollegeGp.png'
import principal from '../../assests/images/rajkumar.png'
const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
    caption:"Annual Day Photos"
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
    caption:"Convocation Photos"
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    caption:"Seminar Photos"  
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
    caption:"Seminar Photos"  
  },
];
const imgProps={
  0: {
      items: 1,
  },
  1024: {
      items: 2,
      itemsFit: 'contain',
  }
};

export default class AboutUsHomepageView extends Component {
  
  render() {
    return (
      <div className='container sec p-2'>
        <Row >
            <Col  style={{textAlign:"center", justifyContent:"center", paddingTop:"3%"}}>

            <img className='img-fluid'  src={imgvjgp} alt='' />
        
                
            </Col>
           
        </Row>
        
      </div>
    )
  }
}
