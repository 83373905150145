import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

export default function DocPhotoProof(props) {
    const inputRef = React.useRef();
    const { profileImage, setImageFile}=props;
    const { enqueueSnackbar } = useSnackbar();

useEffect(() => {
  //setImage(`${config.s3.imageURL}${profileImage}`);
 // console.log("profile image = ", `${config.s3.imageURL}${profileImage}`);
  
}, [])

   const handleFileSelection = (event)=>{
     event.preventDefault();
     if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
       //console.log("selected file : ", event.target.files[0].name);
      // var tprofile = document.getElementById("profile");
       //tprofile.src=URL.createObjectURL(event.target.files[0]);
       enqueueSnackbar("File Selected - "+ event.target.files[0].name,{variant:'info'});
      }else{
    enqueueSnackbar("No File Selected",{variant:'info'});
   }
  }

    return (
      <div style={{textAlign:"left"}}> 
        <p>A Passport Size Photo</p>      
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
          //style={{ display: "none" }}
        />
       {/*} <span style={{backgroundColor:'primary', width:'120px', height:'120px', borderRadius:"20%", position:'relative', left:'-20px', top:'35px', zIndex:"999"}}>
            <i className='overlay-profile fa fa-camera' onClick={triggerFileSelectPopup} style={{ border:'2px solid white', borderRadius:'50%',color:'black', left:'-20px', top:'35px', fontSize:'20px', zIndex:'500', cursor:'pointer'}}></i>
    </span>*/}
        </div> 
    )
}