import React from 'react';
import { Helmet } from 'react-helmet';
//import AboutUsHeader from './AboutUsHeader';
import AboutUsContent from './AboutUsContent';
//import NewFaceView from '../NewFaceView';
import VidyaJyotiBanner from '../VidyaJyotiBanner';

export default function AboutUsMain() {
  return (
    <div>
      <Helmet>
        <title>About Us - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
        <meta name="description" content="About Us - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
        <meta name="keywords" content="About Us - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
        <link rel="canonical" href="/about" />
      </Helmet>
      {/*<AboutUsHeader />
      <NewFaceView />*/}
      <VidyaJyotiBanner />
      <AboutUsContent />
    </div>
  )
}
