import React from 'react';
//import LibraryHeader from './LibraryHeader';
import { latestArrivalList } from '../../ProData';
//import NewFaceView from '../NewFaceView';
import {Helmet} from "react-helmet-async";


export default function LatestArrivalsView() {
  return (
    <>
    <Helmet>
      <title>Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/library/latest" />
    </Helmet>
       {/*} <LibraryHeader />
        <NewFaceView />*/}
        <div className="container-fluid blog py-5 mb-5">
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary">Our Library</h5>
                    <h1>Latest Arrivals - {latestArrivalList.length}</h1>
                </div>
                <div className="row g-5 justify-content-center">
                {latestArrivalList.map((item, index)=>
                    <div key={index} className="col-lg-6 col-xl-3 wow fadeIn" data-wow-delay=".3s">
                        <div className="blog-item position-relative bg-light rounded">
                            <img src={item.poster} className="img-fluid w-100 rounded-top zoom"  alt="" style={{width:"80px", height:"300px"}} />
                            <span className="position-absolute px-4 py-3  bg-dark-main text-white rounded" style={{top: "-28px", right: "20px"}}>{item.year}</span>
                            <div className="blog-content text-center position-relative px-3" style={{marginTop: "-25px"}}>
                                <img src={item.authorPic} className="img-fluid rounded-circle border border-4 border-white mb-3" alt="" style={{height:"90px", width:"90px"}}/>
                                <h5 className="">{item.author}</h5>
                            </div>
                            <div className="blog-coment d-flex justify-content-between px-4 py-2 border Ylight-bg rounded-bottom">
                            <small className="text-dark-main"> {item.book}</small>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </>
    )
}
