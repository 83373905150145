import React from 'react';
import {Helmet} from "react-helmet-async";


export default function PrivacyPolicy() {
  return (
    <>
    <Helmet>
      <title>Policy - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Policy - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Policy - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/privacy-policy" />
    </Helmet>

    <h5 className="text-primary">Privacy Policy</h5>


    <p style={{textAlign:"justify", lineHeight:"28px"}}>Thank you for visiting the Vidyajyoti College website and reviewing our privacy policy.</p>

    <p style={{textAlign:"justify", lineHeight:"28px"}}>We respect the privacy of those who visit our college web site. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users </p>

    <p style={{textAlign:"justify", lineHeight:"28px"}}>We collect no personal information about you unless you choose to provide that information to us. We do not give, share, sell, or transfer any personal information to a third party. If you want to know more about how we record non-personal information about your visit or how we use information that you voluntarily submit, read on</p>
<h1 className="mb-4">Non-personal Information We Record</h1>

<p style={{textAlign:"justify", lineHeight:"28px"}}>If you do nothing during your visit but browse through the website, read pages, or download information, our website's operating system will automatically record some general information about your visit.</p>

<h5 className="text-primary">During your visit, our web operating system will record:</h5>
<ol>
    <li>The type of Operating System that you use (such as Windows, Unix, or Mac).</li>
    <li>The type of Browser (such as "Netscape version X" or "Internet Explorer version X") that you are using.</li>
    <li>The Date and Time you visit our site, and the web pages that you visit on our site.</li>
    <li>The Internet Domain for your Internet service, such as "xyz.com" or "xyz.net" if you use a private Internet access account or "yourcollege.edu" if you connect from a college or university domain.</li>
</ol>
<p style={{textAlign:"justify", lineHeight:"28px"}}>We use this information for statistical analysis, to help us make our site more useful to visitors. This tracking system does not record information about individuals.</p>
</>
  )
}
