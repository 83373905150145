import React, { Component } from 'react';
import {Helmet} from "react-helmet-async";

export default class VJDEPThContent extends Component {
  render() {
    return (
        <div className='sec container'>
        <Helmet>
          <title>Accademics - VJDEPTh - Vidyajyoti Distance Education Programme in Theology</title>
          <meta name="description" content="Formation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
          <meta name="keywords" content="Formation - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
          <link rel="canonical" href="/about/formation" />
        </Helmet>
        <div>
            <h1 className="mb-4" style={{textAlign:"center"}}>OUTGOING THEOLOGY</h1>
            <h5 className="text-dark-main text-center mb-4"><a href='https://www.vjdepth.in/'> <u>Visit Us @ www.vjdepth.in </u></a></h5>
                    <h5 className="light-text-combo">Theological Formation through Distance Education (VJ-DEPTH)</h5>
                    <p style={{color:"black", textAlign:"justify", lineHeight:"35px"}}>
                    Keeping in line with Pope Francis’s call to “CHURCH GOING FORTH…” Vidyajyoti has been making special efforts to equip and educate our Laity in Theological and Scriptural studies. ‘VJ-DEPTh’ is Vidyajyoti’s Distance Education Programme in Theology. It was Dr. John Misquitta SJ, of Bombay province, who visualised this programme for Catholic lay faithful in collaboration with the faculty of Vidyajyoti, which was called “Do it yourself theology” and the same was incorporated into Vidyajyoti by Dr. George Mlakuzhiyil SJ.                     </p>
                    <p style={{color:"black", textAlign:"justify", lineHeight:"35px"}}>    
                    However, the present form of VJ-DEPTh is a new version, which is growing by the grace of God by leaps and bounds. Dr. P.T. Mathew SJ and Prof. Rajkumar Joseph SJ have updated the entire programme and brought in fresh initiatives with passionate-personal approach to make it more learner-friendly.  The current director is Dr. Fr. Anil D’ Almeida SJ.  DEPTh also has Sunday online classes to reach out to all parts of the nation and abroad.                     
                    </p>
                    <p style={{color:"black", textAlign:"justify", lineHeight:"35px"}}>    
                    Currently VJ-DEPTh has more than 400 students enrolled. In this, 67 of them hail from Malaysia, some from Dubai and a few from Mauritius, Zurich, and Sri Lanka. So far, more than 200 have graduated from all over India and abroad.  The second batch of about 55 learners from Malaysia have completed  successfully. Thus, VJ-DEPTh is regularly sending out Theology Diploma Graduates into the universal Church who actively involve themselves in various apostolate of the Church such as pastoral animation, youth formation, conducting webinar on synodality, Catechism classes, etc. 
                    </p>

                </div>
                <div style={{color:"black", textAlign:"justify", lineHeight:"35px"}}>
                    <p>
                <h5 className="text-primary">Prominent Features of VJ-DEPTh are as follows: </h5>
                    <ol>
                        <li>Regular Contact classes and Eucharist at regional centeres. </li>
                        <li>Interactive Student-friendly Website: www.vjdepth.in & WhatsApp forum for discussion.</li>
                        <li>Regular online classes on alternate Sundays from 5.00 pm to 7.00 pm. </li>
                        <li>Phase End Mini Comprehensive exams in the regional centres nearer to the candidates.</li>
                        <li>Annual Seminar and Convocation ceremony aimed to provide Theological Education to Local Delhi Catholics.</li>
                        <li>Yearly Laity Webinar on Papal documents.</li>
                        <li>VJ-DEPTh magazine (Totally Managed by the Laity) to reflect and contextualize by writing creatively and theologically. </li>
                    </ol>
                    </p>
                    </div>
                    <p style={{color:"black", textAlign:"justify", lineHeight:"35px"}}>    
                    When viewed through the lens of theological formation of the laity which Veritatis Gaudium envisages and encourages, DEPTh is an eminent expression and example of the ‘way forward’ to form our men and women in Christian and social thought (cf. VG 1). VJ-DEPTh equips our laity to engage in the apostolate proper to them which “derives from their Christian vocation” (Apostolicam Actuositatem, n. 1) and exercise the mission which God entrusted to the Church to fulfil in the world by witnessing, evangelizing and catechizing in the neighbourhood and in the parishes.
                    </p>

      </div>
    )
  }
}
