import React from 'react'

export default function PublicationHeader() {
  return (
    <div>
         <div className="container-fluid page-header py-1">
            <div className="container text-center py-1">
                <h1 className="display-2 text-white mb-4 animated slideInDown">Publications</h1>
                {/*<nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb justify-content-center mb-0">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Pages</a></li>
                        <li className="breadcrumb-item" aria-current="page">Publications</li>
                    </ol>
  </nav>*/}
            </div>
        </div>
    </div>
  )
}
