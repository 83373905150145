import React from 'react';
import { useSnackbar } from 'notistack';

//import axios from "axios";
//import { Auth } from "aws-amplify";
//import useFullPageLoader from "../hooks/useFullPageLoader";
//import { ProductContext } from '../../ProductProvider';
//const config = require('../../config.json');


export default function DocMarkList(props) {
    const inputRef = React.useRef();
    const {setMarkListFile }=props;
    const { enqueueSnackbar } = useSnackbar();

   const handleFileSelection = (event)=>{
     event.preventDefault();
     if (event.target.files && event.target.files.length > 0) {
      setMarkListFile(event.target.files[0]);
       //console.log("selected file : ", event.target.files[0].name);
      // var tprofile = document.getElementById("profile");
      // tprofile.src=URL.createObjectURL(event.target.files[0]);
      enqueueSnackbar("File Selected - "+ event.target.files[0].name,{variant:'info'});
   }else{
    enqueueSnackbar("No File Selected",{variant:'info'});
   }
  }

    return (
      <div style={{textAlign:"left"}}>     
      <p>Attested True Copy of Philosophy Mark Sheet </p>
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
        />
      </div>
    )
}