import React, {useEffect, useState, useContext} from 'react';
import { Col, Row, Card, Button, Table, FormGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

export default function InstitutionForm(props) {
  const [localStudy, setLocalStudy]=useState({id:"", instituteName:"", degree:"",studyYear:""});
  const [pastoral, setPastoral] = useState('Pastoral');
  const {form, setForm,  formType} = props;
  const [viewOnly, setViewOnly] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    if(formType==="Edit"){
      setViewOnly(false);
      generateApplicationNo();
    }else{
      setViewOnly(true);
    }
  }, [])

  const generateApplicationNo=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    var date_seconds = (current_datetime.getSeconds().toString());
    var seconds_value="";
    date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=date_seconds);
  
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value, seconds_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    //return accountNo;
    //console.log("acc : ", accountNo);
    //setLocalStudy({...localStudy, id:accountNo});
    
    setLocalStudy({id:accountNo,instituteName:"", degree:"",studyYear:""});
  }
  
    const handleStudyAdd =async(event)=>{
      event.preventDefault();
      if(localStudy.instituteName===""||localStudy.degree===""||localStudy.studyYear===""){
        enqueueSnackbar("Acadamic Fields cannot be empty. Enter all Fields!",{variant:'info'});
      }else{
        await setForm({...form, degreeDetails:[...form.degreeDetails, localStudy]});
          //setLocalStudy({instituteName:"", degree:"",studyYear:""});
          generateApplicationNo();
      }
    }
  
    const handleRemoveStudy=async(event, member)=>{
      event.preventDefault();
       //console.log("item : ", item);
        let tempCart = [...form.degreeDetails];
        tempCart = tempCart.filter(item => item.id !==member.id);
       // console.log("temp cart :", tempCart);
       await setForm({...form, degreeDetails:[...tempCart]});
       // calculateProgressiveValues(memberInfo);
       // setStudyDetails([...tempCart]);
    }
  

  return (
    <div>

<div>
    <Card >
      {!viewOnly?<Card.Body>
        <Form>
       {/*} <Form.Group as={Row} className="mb-3" controlId="formPlaintextTitle">
      <Form.Label column sm="6">Field Experience  </Form.Label>
        <Col sm="6">
        <Form.Select size="lg" style={{fontSize:16, borderRadius:10, padding:8, width:"100%"}}
                id="title"
                value={pastoral} 
                disabled={viewOnly}
                onChange={(event)=>{setPastoral(event.target.value)}}>
                  <option value="Pastoral">Pastoral </option>
                  <option value="Professional">Professional</option>
                  </Form.Select>
        </Col>
        </Form.Group>*/}
        <h5>Academic Information</h5>
          <Row>
            <Col> 
              <div className="navbar-bg" style={{marginBottom:'20px'}}>
                <FormGroup style={{overflow:"visible"}}>
              <div>
            <Row>
            
                <Col xs={9} lg={3}>
                <Form.Control type="text" name="place"
                    placeholder='Name of the Course'
                    onChange={(event)=>{setLocalStudy({...localStudy,
                      degree:(event.target.value).toUpperCase()})}}
                    value={localStudy.degree}
                />
                 
                </Col>
                <Col xs={9} lg={3}>
                <Form.Control
                    type="text"
                    name="institutionname"
                    placeholder="Name & Location of Institute"
                    onChange={(event)=>{setLocalStudy({...localStudy,
                      instituteName:(event.target.value).toUpperCase()})}}
                    value={localStudy.instituteName}
                />
                </Col>
                <Col xs={9} lg={3}>
                  <Form.Control type="number" name="year" placeholder='Year of Completion'
                    onChange={(event)=>{setLocalStudy({...localStudy,
                      studyYear:event.target.value})}}
                    value={localStudy.studyYear}
                  />
                 </Col>
                 <Col xs={9} lg={1}>
                 {formType==="Edit" && <Button title="Add User" onClick={(event)=>{handleStudyAdd(event)}} >ADD</Button> }
                 </Col>
               </Row>
                  </div>
            </FormGroup>
       </div>
       </Col>           
        </Row>






        </Form>
      </Card.Body>:null}
        <p className='p-2'>Degree Details:</p>
        {form.degreeDetails ?<Table hover responsive >
        <thead className='theadStyle'>
            <tr>
            <th>
                #
            </th>
            <th>
            Degree / Diploma
            </th>
            <th>
            Name of the Institute
            </th>
            
            <th>
            Year of Completion
            </th>
            </tr>
        </thead>
        <tbody className='tbodyStyle'>
        {form.degreeDetails.map((item, index)=>
            <tr key={index} className='trStyle'>
            <th scope="row">
            {index+1}

            </th>
            <td>
            
            {item.degree}
            </td>
            <td>
            {item.instituteName}
            </td>
            <td>
                {item.studyYear}
            </td>
            {!viewOnly? <td>
            <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveStudy(event, item)}}/>
        </td>:null}
            </tr>)}
        </tbody>
        </Table>:<p>No Degree details found</p>}
    </Card>
    </div>
       {/*} <h5 className="text-primary">LIST IN CHRONOLOGICAL ORDER ALL YOUR STUDIES (SECULAR OR RELIGIOUS) AND THE DEGREE OR DIPLOMA OBTAINED</h5>
         <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        <th>Name of the Institute</th>
                        <th>Location</th>
                        <th>Year Studied</th>
                        <th>Degree / Diploma Obtained</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {form.degreeDetails && form.degreeDetails.map((item, index)=>
    <tr key={index}>
      <th scope="row">
      {index+1}
      </th>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Institution Name" 
      value={item.institutionName} disabled onChange={event=>setForm({...form, institutionName:event.target.value})}/>
      </td>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Location" 
      value={item.location} disabled onChange={event=>setForm({...form, location:event.target.value})}/>
      </td>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Year Studied" 
      value={item.yearStudied} disabled onChange={event=>setForm({...form, yearStudied:event.target.value})}/>
      </td>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Degree / Diplamo details" 
      value={item.degreeObtained} disabled onChange={event=>setForm({...form, degreeObtained:event.target.value})}/>
      </td>
      <td>
      </td>
     {/*!viewOnly? <td>
      <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveStudy(event, item)}}/>
  </td>:null*/}
   {/*} </tr>)}
    <tr key={form.degreeDetails.length+1}>
      <th scope="row">
      {form.degreeDetails.length+1}
      </th>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Institution Name" value={form.institutionName}
                            onChange={event=>setForm({...form, institutionName:event.target.value})}/>
      </td>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Location" value={form.location}
                            onChange={event=>setForm({...form, location:event.target.value})}/>
      </td>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Year Studied" value={form.yearStudied}
                            onChange={event=>setForm({...form, yearStudied:event.target.value})}/>
      </td>
      <td>
      <input type="text" className="form-control border-0 py-3" placeholder="Degree / Diplamo details" value={form.degreeObtained}
                            onChange={event=>setForm({...form, degreeObtained:event.target.value})}/>
      </td>
      <td>
      <i className="bi bi-plus-circle-fill"></i>
      <i className="bi bi-dash-circle-fill"></i>
            </td>
     {/*!viewOnly? <td>
      <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleRemoveStudy(event, item)}}/>
  </td>:null*/}
   {/*} </tr>

    </tbody>
</table>*/}
    </div>
  )
}
