import React from 'react';
//import AdmissionHeader from './AdmissionHeader'
//import FaceView from '../FaceView'
//import NewFaceView from '../NewFaceView'
import {Helmet} from "react-helmet-async";

export default function AdmissionDetails() {
  return (
    <>
    <Helmet>
      <title>Admission Details - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Admission Details - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Admission Details - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/admission/details" />
    </Helmet>

    {/*<AdmissionHeader />
    <FaceView />
    <NewFaceView />*/}
    <div className='sec container' style={{padding:"10"}}>
        <h1 className="mb-3" style={{textAlign:"center", padding:"10px"}}>Admission Details</h1>
        <p style={{color:"black", textAlign:"justify", lineHeight:"28px", marginLeft:"10"}}>VIDYAJYOTI invites applications for admission to graduate, postgraduate (B.Th (Degree, Certificate and Diploma, M.Th, Phd) programs at Vidyajyoti Faculty of Theology, 4-A Raj Niwas Marg, Delhi – 110 054. The Academic Year 2024-2025 begins on Monday, 08 July 2024.</p>

        <div style={{color:"black", textAlign:"justify", lineHeight:"28px"}}>
                    <p>
                <h5 className="text-primary">Read on for more guidelines below:</h5>
                    <ol>
                        <li>The Vidyajyoti Faculty (Vidyajyoti Statutes 5,2) in keeping with the Apostolic Constitution Veritatis Gaudium (V.G. 31), the Institute is in principle open to all students, clerical, religious and lay, who are duly qualified.</li>
                        <li>Admissions are granted by the Principal at the recommendation of the Admissions Board, having examined the application, the required evidence of character and academic qualifications of the student in question (Art. 26,1).</li>
                        <li>Students seeking admission for the first degree must have done two years of Philosophy (Art. 26,3).</li>
                        <li>Regular attendance at lectures and participation in the prescribed academic exercise will be required from all Degree Course students. The same norms will apply, mutatis mutandis, to Diploma and Certificate Course students (Art. 28,1).</li>
                        <li>Students who miss more than one-third of the classes and/or do not comply with other academic requirements will be debarred from examinations by the Principal after consulting the Executive Committee or the Disciplinary Board (Art. 28,1).</li>
                    </ol>
                    </p>
                </div>

<p style={{color:"black", textAlign:"justify", lineHeight:"28px", marginLeft:"10"}}>For queries contact:  Registrar: vjregis@gmail.com Principal: principalvj@gmail.com </p>

<p style={{color:"black", textAlign:"justify", lineHeight:"28px", marginLeft:"10"}}>If you face problems in submitting your applications please contact +91-9971186877</p>
<p style={{color:"black", lineHeight:"35px", textAlign:"right"}}><h4>Prof. Dr. Fr. Rajakumar Joseph SJ</h4>
Vidyajyoti College of Theology,<br/>
Institute for Religious Studies,<br/>
Delhi – 54. 
</p>
    </div>
    </>
  )
}
