
export const latestArrivalList=[{
    id:"poster1",
    author:"Abraham, K.A.",
    book:"Rethinking Hope: Christian Eschatology Rediscovered.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster1.jpeg'),
    authorPic:require('./assests/img/defaultAvatar.jpg')

  },{
    id:"poster2",
    author:"Abraham, Molly",
    book:"A Saga of Tolerance and a Legacy for Posterity: Catholic Visionaries and Women’s Empowerment in North India.",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster2.jpeg'),
       authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster3",
    author:"Aggarwal, Suman Khanna",
    book:"The Science of Peace.",
    year:"2019",
    publisher:"New Delhi: Shanti Sahyog Center for Peace and Conflict Resolution",
    poster:require('./assests/img/poster/poster3.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster4",
    author:"Amaladass, Vinothraj",
    book:"Breaking Pyramid: A Dalit-Self’s New Humanity",
    year:"2020",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster4.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster5",
    author:"Ansari, Tahir Hussain",
    book:"Mughal Administration and the Zamindars of Bihar",
    year:"2019",
    publisher:"New Delhi: Manohar",
    poster:require('./assests/img/poster/poster5.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster6",
    author:"Baptist, John and Team (eds.)",
    book:"St Joseph: Shadow of the Heavenly Father.",
    year:"2021",
    publisher:"Mumbai: Pauline",
    poster:require('./assests/img/poster/poster6.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster7",
    author:"Chalcraft, David J. and Zhodi Angami (eds.).",
    book:"A Biblical Masala: Encountering Diversity in Indian Biblical Studies.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster7.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')

  },{
    id:"poster8",
    author:"Chhotebhai",
    book:"The Jerusalem Code.",
    year:"2020",
    publisher:"Kanpur: Indian Catholic Forum",
    poster:require('./assests/img/poster/poster8.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster9",
    author:"Christopher, Sam",
    book:"Jesus the King: A Paradigm for Liberation (Christology of Luke’s Gospel)",
    year:"2019",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster9.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster10",
    author:"D’Souza, Wilson",
    book:"A Choice between Light and Darkness: An Exegetical, Theological and Contextual Reading of John 8:12.",
    year:"2021",
    publisher:"Delhi: Media House",
    poster:require('./assests/img/poster/poster10.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster11",
    author:"Daniel, Monodeep and Samuel John Shekhar",
    book:"Flourishing Communities: Insights from Ambedkar and Bible",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster11.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster12",
    author:"Doss, Jesu Pudumai, Sahaydas Fernando and Maria Charles Antonysamy (eds.)",
    book:"Pathways to Fraternity: Educating the Young in the Lights of ‘Fratelli Tutti’",
    year:"2021",
    publisher:"New Delhi: All India Don Bosco Education Society",
    poster:require('./assests/img/poster/poster12.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster13",
    author:"Edwin, Joseph Victor",
    book:"A New Spirit in Christian-Muslim Relations in India: Three Jesuit Pioneers",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster13.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster14",
    author:"Ferrao, Victor",
    book:"Being Human in a World of Artificial Intelligence: Quest for Fusions of Sangam",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster14.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster15",
    author:"Haidar, Mansura",
    book:"Mirza Muhammad Haidar Dughlat Kurkan",
    year:"2019",
    publisher:"New Delhi: Manohar Publishers",
    poster:require('./assests/img/poster/poster15.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster16",
    author:"Inamdar, Areef",
    book:"Political Ideas of Dr. Zakir Husain",
    year:"2019",
    publisher:"New Delhi, Madhav Books",
    poster:require('./assests/img/poster/poster16.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster17",
    author:"Jayard, S. Stephen",
    book:"Mary for Modern Times: Reflections on Meaning and Message of Marian Devotion.",
    year:"2019",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster17.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster18",
    author:"Jones, J. Stanly",
    book:"The Jesus Movement and Its Resistance to Oppressive Institutions: A Study of Healing Narratives in Mark’s Gospel.",
    year:"2020",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster18.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster19",
    author:"Jose, V.M.",
    book:"Towards a Local Tribal Church: An Anthropological and Missiological Exploration of the Jesuits among the Santals.",
    year:"2021",
    publisher:"Pune: Jnana-Deepa Vidyapeeth and Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster19.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster20",
    author:"Joy, C.I. David and Ebenezer Shinekumar (eds.).",
    book:"Subaltern Historiography: A Reader.",
    year:"2020",
    publisher:"Delhi: ISPCK, Hydrabad: Henry Martyn Institute and Kerala: Kerala United Theological Seminary",
    poster:require('./assests/img/poster/poster20.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster21",
    author:"Kalambukattu, Naiju Jose",
    book:"Theologizing in Context: A Hermeneutic of Living Dialogue between the Word and the World.",
    year:"2019",
    publisher:"Bengaluru: Dharmaram Publications",
    poster:require('./assests/img/poster/poster21.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster22",
    author:"Karimundackal, Thomas (ed.).",
    book:"Faith – Freedom – Commitment: Biblical and Theological Reflections for Today Inspired by George M. Soares-Prabhu, S.J.",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster22.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster23",
    author:"Karunanidhi, Yesu",
    book:"Divine – Human Symphony: A Comparative Reading of Ecclesiastes and Pattinattar",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster23.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster24",
    author:"Kumaradoss, Y. Vincent",
    book:"From the Pen of Scholar-Missionary Robert Caldwell (1814-1891): Selected Letters and Other Writings",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster24.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster25",
    author:"Lalenkawla",
    book:"Socio-Political Significance of Covenant Motif in the Old Testament: Reading the Theology of Prophet Jeremiah from a Tribal Perspective.",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster25.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster26",
    author:"Lalrinliana, R",
    book:"Church and State Relations in Pauline Epistles: Its Implications for Religion and Politics in Mizoram.",
    year:"2019",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster26.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster27",
    author:"Lalrinthanga, H",
    book:"Gospel and Culture: An Interaction between Christianity and Mizo Culture",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster27.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster28",
    author:"Louis, Prakash",
    book:"Fr. Stan Swamy: A Maoist or a Martyr? ",
    year:"2021",
    publisher:"Delhi: Media House and Sahithya Pravarthaka Co-operative Society Ltd.",
    poster:require('./assests/img/poster/poster28.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster29",
    author:"Lourduraj, William M.",
    book:"C. J. Beschi Sj (Veeramamunivar): A Missionary, Tamil Scholar, and Theologian",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster29.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster30",
    author:"Lunggleng, Lengmi",
    book:"Interfaith Marriage: Pastoral Concerns",
    year:"2019",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster30.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster31",
    author:"Mangai, Poulose and Joseph Victor Edwin (eds.)",
    book:"Seeing the Other in the Mirror of Christ: 800th Anniversary of the Saint-Sultan Encounter",
    year:"2021",
    publisher:"Delhi: Media House",
    poster:require('./assests/img/poster/poster31.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster32",
    author:"Marchetto, Agostino and Angelo Federico Arcelli.",
    book:"Communion: Reflections for an Intra-Ecclesial Dialogue.",
    year:"2021",
    publisher:"Soveria Mannelli: CLE",
    poster:require('./assests/img/poster/poster32.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster33",
    author:"",
    book:"Riflessioni: Per un dialogo intraecclesiale.",
    year:"2021",
    publisher:"Soveria Mannelli: Rubbettino",
    poster:require('./assests/img/poster/poster33.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster34",
    author:"Mathew, Sinimol",
    book:"From Glory to Holy: An Inner Life Journey through the Renewed Temple of Prophet Ezekiel and Spiritual Doctrines of St. John of the Cross.",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster34.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster35",
    author:"Mathias, R",
    book:"Word of God: To Preach and to Practice Homilies for Sundays and Solemnities.",
    year:"2019",
    publisher:"Bengaluru: SFS Publications",
    poster:require('./assests/img/poster/poster36.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster36",
    author:"Mendonca, Valerian (ed.)",
    book:"Ancient, Medieval and Modern Philosophy: A Historical Introduction (500 BC-1800 AD)",
    year:"2021",
    publisher:"Pune: Jnana-Deepa Vidyapeeth and Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster36.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster37",
    author:"",
    book:"Contemporary Philosophy: A Historical Introduction (1800-2020 AD).",
    year:"2021",
    publisher:"Pune: Jnana-Deepa Vidyapeeth and Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster37.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster38",
    author:"Miller, Robert D",
    book:"The Oral World of the Old Testament: A Historical Preview.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster38.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster39",
    author:"Oliparambil, Joseph",
    book:"Human Responsibility in Salvation: Understanding Christian Soteriology in Hindu Context.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster39.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster40",
    author:"Oosterhuis, Melle H",
    book:"Blessed are the Pure in Heart: Bible Studies in the Old and the New Testaments.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster40.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster41",
    author:"Pakhuongte, Fidelia Lallawmzuali",
    book:"An Evaluation of the Old Testament Women against the Ideal Woman in Proverbs.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster41.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster42",
    author:"Prasuna, Nelavala Gnana",
    book:"Engendering the Divine Image: Conversation with Dalit Women’s Experience.",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster42.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster43",
    author:"Punnackad, Mathew Koshy",
    book:"The Green God of the Bible.",
    year:"2020",
    publisher:"Delhi: ISPCK and Chennai: Church of South India",
    poster:require('./assests/img/poster/poster43.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster44",
    author:"Purakary, Clare",
    book:"Oraon Christian’s Way of Life: Interaction of Faith and Culture.",
    year:"2019",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster44.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster45",
    author:"Raja, Joshua, et al",
    book:"From Cultures of Conflict to Culture of Peace: Witnessing Christ in Multi-Faith Contexts.",
    year:"2020",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster45.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster46",
    author:"Ralte, Rodinmawia",
    book:"Inter-Religious Relations in India: Challenges and Prospects.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster46.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster47",
    author:"Ray, Aniruddha",
    book:"The Sultanate of Delhi (1206-1526): Polity, Economy, Society and Culture.",
    year:"2019",
    publisher:"New Delhi",
    poster:require('./assests/img/poster/poster47.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster48",
    author:"Regulas, Bouvert",
    book:"Holy Communion",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster48.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster49",
    author:"",
    book:"Revelation of Jesus Christ.",
    year:"2019",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster49.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster50",
    author:"Robertson, Selvam",
    book:"Understanding the Role of Religion in Building up Sustainable Existence: Issues and Challenges.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster50.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster51",
    author:"Rupwate, Daniel",
    book:"Covenantal Relationship Between the Lord God and His People: Nine Textual Sermons on Covenant Sunday or New Year Sunday with ‘The Covenant Theology of the Reverend John Wesley’ and an Enlarged Historical Preface to the “Covenant with God Service”",
    year:"2019",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster51.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster52",
    author:"Sadananda, Daniel Rathnakara and Paul J. Solomon (eds.)",
    book:"Rethinking Ecclesia: Being and Becoming Christ Communities: Towards a Borderless Church. Biblical and Theological Perspectives. Vol. I",
    year:"2021",
    publisher:"Delhi: ISPCK and Chennai: The Church of South India",
    poster:require('./assests/img/poster/poster52.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster53",
    author:"",
    book:"Rethinking Ecclesia: Being and Becoming Christ Communities: Towards a Borderless Church. Liturgical, Missiological and Prophetic Diaconal Perspectives. Vol. II.",
    year:"2021",
    publisher:"Delhi: ISPCK and Chennai: The Church of South India",
    poster:require('./assests/img/poster/poster53.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster54",
    author:"",
    book:"Rethinking Ecclesia: Being and Becoming Christ Communities: Towards a Borderless Church. National and Global Ecumenical Perspectives. Vol. IV.",
    year:"2021",
    publisher:"Delhi: ISPCK and Chennai: The Church of South India",
    poster:require('./assests/img/poster/poster54.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster55",
    author:"Selvanayagam, Israel",
    book:"Interreligious Life Issues: Relationship, Education, Prayer, Marriage, Funeral and Engagement.",
    year:"2019",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster55.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster56",
    author:"Singsit, Lalboi",
    book:"Christian Theology and Tribal Culture: In Dialogue with C.S. Song",
    year:"2019",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster56.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster57",
    author:"Smmi, Devapriya",
    book:"Woman You Are Set Free",
    year:"2020",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster57.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster58",
    author:"Somi, Jajo",
    book:"Retributive Justice and Ethics of Love Relating to Rape Victim",
    year:"2019",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster58.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster59",
    author:"Stanislaus, Lazar T. and Christian Tauchner (eds.)",
    book:"Becoming Intercultural: Perspectives on Mission",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster59.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster60",
    author:"Syiemlieh, David R. and Ivan D’Silva (eds.)",
    book:"Legacy of Catholic Church in Tripura (1996-2021): Pathways Ahead",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster60.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster61",
    author:"Thainis, A",
    book:"John Peter – The Servant of God: A Complete Documentation of Original Sources",
    year:"2021",
    publisher:"Trichy: Franciscan Oli Publication",
    poster:require('./assests/img/VJ-College-logo.png'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster62",
    author:"Thomas, Arun",
    book:"Healing Discourse in the Gospel of Mark: A Socio-Political Reading",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster62.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster63",
    author:"Thomas, M.C",
    book:"Othering and Embodiment: Intersectional Imaginations in the Old Testament Narratives",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster63.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster64",
    author:"Vallooran, Augustine",
    book:"In search of the Absolute: A Critical Study of the Advaitic Philosophy of the Equality of Religions",
    year:"2021",
    publisher:"Bengaluru: ATC Publishers",
    poster:require('./assests/img/poster/poster64.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster65",
    author:"Vashum, Yangkahao",
    book:"Faith Seeking Transformation: Rethinking Faith, Theology and Mission in North India",
    year:"2020",
    publisher:"Delhi: Christian World Imprints",
    poster:require('./assests/img/poster/poster65.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster66",
    author:"Vattamattam, Sebasitna (ed.)",
    book:"Collected Works of Sebastian Kappen: Jesus and Freedom and Related Essays. Vol. I.",
    year:"2020",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster66.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster67",
    author:"",
    book:"Collected Works of Sebastian Kappen: Jesus and Cultural Revolution and Related Essays. Vol. III.",
    year:"2020",
    publisher:" Delhi: ISPCK",
    poster:require('./assests/img/poster/poster67.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster68",
    author:"",
    book:"Collected Works of Sebastian Kappen: Liberation Theology and Marxism and Related Essays. Vol. IV",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster68.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster69",
    author:"",
    book:"Collected Works Sebastian Kappen: Hindutva and Indian Religious Traditions. Vol. V.",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster69.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster70",
    author:"Wilfred, Felix",
    book:"Theology for an Inclusive World",
    year:"2019",
    publisher:"Delhi: ISPCK",
    poster:require('./assests/img/poster/poster70.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster71",
    author:"Xavier, Francis G",
    book:"100 Great Inspiring Stories.",
    year:"2019",
    publisher:"Mumbai: Jaico Publishing House",
    poster:require('./assests/img/poster/poster71.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  },{
    id:"poster72",
    author:"Xavier, S. Basil",
    book:"Philosophies of Margins: Theoretical Foundations and Illustrative Models of Application.",
    year:"2020",
    publisher:"Madurai: SHANLAX Publications",
    poster:require('./assests/img/poster/poster72.jpeg'),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  }];

  export const coursesOffered=[{
    "courseID":"VJD-01",
    "courseName":"Bachelor of Theology",
    "courseCode":"BTH",
    "duration":"24 months",
    "fees":7000,
    "courseOption":"Correspondence",
    "payOption":"Full",
    "feesOpted":7000
  },{
    "courseID":"VJD-02",
    "courseName":"Master of Theology",
    "courseCode":"MTH",
    "duration":"24 months",
    "fees":7000,
    "courseOption":"Correspondence",
    "payOption":"Full",
    "feesOpted":7000
  },{
    "courseID":"VJD-03",
    "courseName":"Licentiate in Sacred Theology",
    "courseCode":"LTH",
    "duration":"24 months",
    "fees":7000,
    "courseOption":"Correspondence",
    "payOption":"Full",
    "feesOpted":7000
  },{
    "courseID":"VJD-04",
    "courseName":"Doctorate of Theology",
    "courseCode":"DTH",
    "duration":"24 months",
    "fees":7000,
    "courseOption":"Correspondence",
    "payOption":"Full",
    "feesOpted":7000
  }]
  
  export const fieldWorkList=[{
    id:1,
    field:"Hospitals",
    imageSrc:require('./assests/img/poster/hospital.jpeg')
  },{
    id:2,
    field:"Moving to Public Place",
    imageSrc:require('./assests/img/poster/publicplace.jpeg')
  },{
    id:3,
    field:"Parish",
    imageSrc:require('./assests/img/poster/parish.jpeg')
  },{
    id:4,
    field:"Prison",
    imageSrc:require('./assests/img/poster/TiharJail.jpeg')
  },{
    id:5,
    field:"SAMAG",
    imageSrc:require('./assests/img/poster/slumChildren.jpeg')
  },{
    id:6,
    field:"Tribal",
    imageSrc:require('./assests/img/poster/tribalPeople.jpeg')
  },{
    id:7,
    field:"Urban Poor",
    imageSrc:require('./assests/img/poster/streetChildren.jpeg')
  },{
    id:8,
    field:"Youth",
    imageSrc:require('./assests/img/poster/youth.jpeg')
  }]

  export const mainFieldWorkList=[{
    id:1,
    field:"Hospitals",
    imageSrc:require('./assests/img/poster/hospital.jpeg')
  },{
    id:2,
    field:"Moving to Public Place",
    imageSrc:require('./assests/img/poster/publicplace.jpeg')
  },{
    id:3,
    field:"Parish",
    imageSrc:require('./assests/img/poster/parish.jpeg')
  },{
    id:4,
    field:"Prison",
    imageSrc:require('./assests/img/poster/TiharJail.jpeg')
  },{
    id:5,
    field:"SAMAG",
    imageSrc:require('./assests/img/poster/slumChildren.jpeg')
  },{
    id:6,
    field:"Tribal",
    imageSrc:require('./assests/img/poster/tribalPeople.jpeg')
  },{
    id:7,
    field:"Urban Poor",
    imageSrc:require('./assests/img/poster/streetChildren.jpeg')
  },{
    id:8,
    field:"Youth",
    imageSrc:require('./assests/img/poster/youth.jpeg')
  }]

export const AVEList=[{
    id:"ave1",
    author:"Abraham, K.A.",
    book:"Rethinking Hope: Christian Eschatology Rediscovered.",
    year:"2021",
    publisher:"Delhi: Christian World Imprints",
    poster:require("./assests/img/poster/ave1.jpeg"),
   authorPic:require('./assests/img/defaultAvatar.jpg')

  },{
    id:"poster2",
    author:"Abraham, Molly",
    book:"A Saga of Tolerance and a Legacy for Posterity: Catholic Visionaries and Women’s Empowerment in North India.",
    year:"2021",
    publisher:"Delhi: ISPCK",
    poster:require("./assests/img/poster/ave2.jpeg"),
   authorPic:require('./assests/img/defaultAvatar.jpg')
  }];

export const VJTodayList=[{
  id:20,
  issue:"Issue 20",
  title:"VJ Today",
  issueYear:"2023",
  issueMonth:"Dec",
},{
    id:19,
    issue:"Issue 19",
    title:"VJ Today",
    issueYear:"2023",
    issueMonth:"Oct - Dec",
  },{
    id:18,
    issue:"Issue 18",
    title:"VJ Today",
    issueYear:"2023",
    issueMonth:"Apr",
  },{
    id:17,
    title:"VJ Today",
    issue:"Issue 17",
    issueYear:"2022",
    issueMonth:"Oct - Dec",
  },{
    id:16,
    title:"VJ Today",
    issue:"Issue 16",
    issueYear:"2022",
    issueMonth:"July - Sep",
  },{
    id:15,
    title:"VJ Today",
    issue:"Issue 15",
    issueYear:"2022",
    issueMonth:"Jan - Apr",
  },{
    id:14,
    title:"VJ Today",
    issue:"Issue 14",
    issueYear:"2021",
    issueMonth:"Oct - Dec",
  },{
    id:13,
    title:"VJ Today",
    issue:"Issue 13",
    issueYear:"2021",
    issueMonth:"July - Sep",
  },{
    id:12,
    title:"VJ Today",
    issue:"Issue 12",
    issueYear:"2021",
    issueMonth:"Jan - Mar",
  },{
    id:11,
    title:"VJ Today",
    issue:"Issue 11",
    issueYear:"2020",
    issueMonth:"Oct - Dec",
  },{
    id:10,
    title:"VJ Today",
    issue:"Issue 10",
    issueYear:"2020",
    issueMonth:"July - Sept",
  },{
    id:9,
    title:"VJ Today",
    issue:"Issue 9",
    issueYear:"2020",
    issueMonth:"Jan - Mar",
  },{
    id:8,
    title:"VJ Today",
    issue:"Issue 8",
    issueYear:"2019",
    issueMonth:"Oct - Dec",
  },{
    id:7,
    title:"VJ Today",
    issue:"Issue 7",
    issueYear:"2019",
    issueMonth:"July - Sept",
  },{
    id:6,
    title:"VJ Today",
    issue:"Issue 6",
    issueYear:"2019",
    issueMonth:"Jan - Mar",
  },{
    id:5,
    title:"VJ Today",
    issue:"Issue 5",
    issueYear:"2018",
    issueMonth:"Oct - Dec",
  },{
    id:4,
    title:"VJ Today",
    issue:"Issue 4",
    issueYear:"2018",
    issueMonth:"July - Sept",
  },{
    id:3,
    title:"VJ Today",
    issue:"Issue 3",
    issueYear:"2018",
    issueMonth:"Jan - Mar",
  },{
    id:2,
    title:"VJ Today",
    issue:"Issue 2",
    issueYear:"2017",
    issueMonth:"Oct - Dec",
  },{
    id:1,
    title:"VJ Today",
    issue:"Issue 1",
    issueYear:"2017",
    issueMonth:"July - Sept",
  }];

  export const TattvavivekaList=[{
    id:3,
    issue:"Tattvaviveka – 2018",
    title:"Tattvaviveka",
    issueYear:"2018",
    issueMonth:"Oct - Dec",
  },{
    id:2,
    issue:"Tattvaviveka – 2017",
    title:"Tattvaviveka",
    issueYear:"2017",
    issueMonth:"Apr",
  },{
    id:3,
    title:"Tattvaviveka",
    issue:"Tattvaviveka – 2016",
    issueYear:"2016",
    issueMonth:"Oct - Dec",
  }];

  export const visitingFacultyList=[{
    id:1,
    name:"Archbishop Anil J.T. Couto",
    title:"Rector",
    province:"DUM",
    subject:"Ecumenism",
    avatarSrc:require('./assests/images/photos/BishopAnil.png'),
    email:"rectorvj@gmail.com"
  },{
    id:2,
    name:"Bp. Deepak Tauro",
    title:"Registrar & Treasurer",
    province:"GUJ",
    subject:"Spirituality of Prayer",
    avatarSrc:require('./assests/images/photos/defaultAvatar.jpg'),
    email:"vjregis@gmail.com"
  },{
    id:3,
    name:"Fr. Jesuraj",
    title:"Scripture",
    province:"CCU",
    subject:"Church History & Sys. Theology",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"sjvalan@gmail.com"
  },{
    id:4,
    name:"Fr. Jaimon Joseph",
    title:"Scripture",
    province:"BOM",
    subject:"Church in the Middle Ages",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"edwinrods@gmail.com"
  },{
    id:5,
    name:"Fr. George Manimala",
    title:"Systematic Theology",
    province:"AND",
    subject:"Marriage Law & Parish Law",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"principalvj@gmail.com"
  },{
    id:6,
    name:"Fr. Cyprian Creado",
    title:"Moral Theology",
    province:"AND",
    subject:"Bio – Medical Ethics",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"stanallasj@gmail.com"
  },{
    id:7,
    name:"Fr. Nishant",
    title:"Systematic Theology",
    province:"DEL",
    subject:"Sociology of Religion",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"victoredwinsj@gmail.com"
  },{
    id:8,
    name:"Fr. Emmanuel Arockiam",
    title:"Scripture",
    province:"PAT",
    subject:"Pastoral Counselling",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  },{
    id:9,
    name:"Sr. Rani Punnaseril",
    title:"Counselor",
    province:"MAP",
    subject:"Indian Constitution",
    avatarSrc:require('./assests/images/photos/SisterAvatar.jpeg'),
    email:"leanderxalxosj@gmail.com"
  },{
    id:10,
    name:"Fr. Joe Francis",
    title:"Counselor",
    province:"KOH",
    subject:"Early Xtny & Patroogy",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"bonids@gmail.com"
  },{
    id:11,
    name:"Fr. Devanmitra Neelankavil",
    title:"Systematic Theology",
    province:"KAR",
    subject:"Oriental Theology",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"anilksha@gmail.com"
  },{
    id:12,
    name:"Fr. Arjen Tete",
    title:"Scripture",
    province:"KOH",
    subject:"Pneumatology",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:"jameskulvi@gmail.com"
  },{
    id:13,
    name:"Fr. Shantharaj",
    title:"On Sabbatical",
    province:"AND",
    subject:"Ecclesiology",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  },{
    id:14,
    name:"Fr. Praseed IMS",
    title:"On Sabbatical",
    province:"AND",
    subject:"Symbols & sacraments",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  },{
    id:15,
    name:"Fr. Domy MSFS",
    title:"On Sabbatical",
    province:"AND",
    subject:"Canon Law – General Terms",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  },{
    id:16,
    name:"Fr. Sahayaraj",
    title:"On Sabbatical",
    province:"AND",
    subject:"Human & Christian Virtues",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  },{
    id:17,
    name:"Sr. Antonetta Pereira",
    title:"On Sabbatical",
    province:"AND",
    subject:"Mariology",
    avatarSrc:require('./assests/images/photos/SisterAvatar.jpeg'),
    email:""
  },{
    id:18,
    name:"Sr. Margret",
    title:"On Sabbatical",
    province:"AND",
    subject:"Feminist Questions",
    avatarSrc:require('./assests/images/photos/SisterAvatar.jpeg'),
    email:""
  },{
    id:19,
    name:"Ms. Grace David",
    title:"On Sabbatical",
    province:"AND",
    subject:"Theology of Laity",
    avatarSrc:require('./assests/images/photos/defaultAvatar.jpg'),
    email:""
  },{
    id:20,
    name:"Fr. John Chathanatt",
    title:"On Sabbatical",
    province:"AND",
    subject:"Social teaching of the Church",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  },{
    id:21,
    name:"Fr. Bosco",
    title:"On Sabbatical",
    province:"AND",
    subject:"Dalit Questions",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  },{
    id:22,
    name:"Sr. Rashmi Kindo",
    title:"On Sabbatical",
    province:"AND",
    subject:"Tribal Questions",
    avatarSrc:require('./assests/images/photos/SisterAvatar.jpeg'),
    email:""
  },{
    id:23,
    name:"Sr. Nija Vara, MF",
    title:"On Sabbatical",
    province:"AND",
    subject:"Documents of Vatican II",
    avatarSrc:require('./assests/images/photos/SisterAvatar.jpeg'),
    email:""
  },{
    id:24,
    name:"Fr. Ravisekhar Dusi",
    title:"On Sabbatical",
    province:"AND",
    subject:"Pastoral Liturgy",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png'),
    email:""
  }];

  export const officialsList=[{
    id:1,
    title:"Patron",
    subTitle:"Archbishop of Delhi",
    name:"Rt. Rev. Anil J. T. Couto",
    avatarSrc:require('./assests/images/photos/BishopAnil.png')
  },{
    id:2,
    title:"Chancellor",
    subTitle:"Superior General of S.J.",
    name:"Rev. Fr. Arturo Sosa SJ",
    avatarSrc:require('./assests/images/photos/arturo.jpg')
  },{
    id:3,
    title:"Vice- Chancellor",
    subTitle:"Provincial of South Asia",
    name:"Fr. Stanislaus D’Souza SJ",
    avatarSrc:require('./assests/images/photos/stanPOSA.jpg')
  },{
    id:4,
    title:"Rector",
    subTitle:"",
    name:"Fr. Nirmal Raj SJ",
    avatarSrc:require('./assests/images/photos/nirmalrector.JPG')
  },{
    id:5,
    title:"Principal",
    subTitle:"",
    name:"Fr. Rajakumar Joseph SJ",
    avatarSrc:require('./assests/images/photos/raj.JPG')
  },{
    id:6,
    title:"Treasurer",
    subTitle:"",
    name:"Fr. Hector Pinto SJ",
    avatarSrc:require('./assests/images/photos/hector.JPG')
  },
  {
    id:9,
    title:"Registrar & Librarian",
    subTitle:"",
    name:"Fr. Joseph Mathew SJ",
    avatarSrc:require('./assests/images/photos/joemathew.jpg')
  },
  {
    id:7,
    title:"Director of Post-Graduate Studies",
    subTitle:"",
    name:"Fr. Valan C. Antony SJ",
    avatarSrc:require('./assests/images/photos/valan.jpg')
  },{
    id:8,
    title:"Director of Fieldwork",
    subTitle:"",
    name:"Fr. Anil D’Almeida SJ",
    avatarSrc:require('./assests/images/photos/anil.jpg')
  },{
    id:10,
    title:"Administrator",
    subTitle:"",
    name:"Fr. Godfried Ekka SJ",
    avatarSrc:require('./assests/images/photos/godfried.jpg')
  },{
    id:11,
    title:"Director of DEPTh",
    subTitle:"",
    name:"Fr. Anil D’ Almeida SJ",
    avatarSrc:require('./assests/images/photos/anil.jpg')
  },{
    id:12,
    title:"Director of VIDIS",
    subTitle:"",
    name:"Fr. J. Victor Edwin SJ",
    avatarSrc:require('./assests/images/photos/victoredwin.JPG')
  },{
    id:13,
    title:"Editor of Vidyajyoti Journal",
    subTitle:"",
    name:"Fr. Edwin Rodrigues SJ",
    avatarSrc:require('./assests/images/photos/edwinrodi.JPG')
  }];

  export const residentialFacultyList=[{
    id:1,
    title:"Principal",
    subTitle:"",
    name:"Fr. Rajakumar Joseph SJ",
    category:"Systematic Theology & Principal",
    avatarSrc:require('./assests/images/photos/raj.JPG')
  },{
    id:2,
    title:"Director of Post-Graduate Studies",
    subTitle:"",
    name:"Fr. Valan C. Antony SJ",
    category:"Scripture",
    avatarSrc:require('./assests/images/photos/valan.jpg')
  },{
    id:3,
    title:"Editor of Vidyajyoti Journal",
    subTitle:"",
    name:"Fr. Edwin Rodrigues SJ",
    category:"Scripture",
    avatarSrc:require('./assests/images/photos/edwinrodi.JPG')
  },{
    id:4,
    title:"Editor of Vidyajyoti Journal",
    subTitle:"",
    name:"Fr. Stanislaus Alla SJ",
    category:"Moral Theology",
    avatarSrc:require('./assests/images/photos/Stan.jpg')
  },{
    id:5,
    title:"Director of VIDIS",
    subTitle:"",
    name:"Fr. J. Victor Edwin SJ",
    category:"Systematic Theology & Christian-Muslim Dialogue",
    avatarSrc:require('./assests/images/photos/victoredwin.JPG')
  },{
    id:6,
    title:"Director of Fieldwork",
    subTitle:"",
    name:"Fr. Anil D’Almeida SJ",
    category:"Systematic Theology",
    avatarSrc:require('./assests/images/photos/anil.jpg')
  },{
    id:7,
    title:"Director of Fieldwork",
    subTitle:"",
    name:"Fr. Jimmy Dhabi SJ",
    category:"Scripture",
    avatarSrc:require('./assests/images/photos/priestAvatar2.png')
  },{
    id:8,
    title:"Librarian",
    subTitle:"",
    name:"Fr. James Kulvi SJ",
    category:"Scripture",
    avatarSrc:require('./assests/images/photos/James.jpeg')
  },{
    id:9,
    title:"Librarian",
    subTitle:"",
    name:"Fr. Jesuthasan OCD",
    category:"Scripture",
    avatarSrc:require('./assests/images/photos/jesu.jpg')
  },{
    id:10,
    title:"Librarian",
    subTitle:"",
    name:"Fr. Xalxo Leander",
    category:"Counselor",
    avatarSrc:require('./assests/images/photos/xalxo.jpg')
  },{
    id:11,
    title:"Librarian",
    subTitle:"",
    name:"Fr. Boniface D’Souza",
    category:"Counselor",
    avatarSrc:require('./assests/images/photos/boni.jpg')
  }];

  export const cstList=[{
    id:1,
    date:"08 - 09 July",
    subjects:"Inauguration and Orientation",
    by:"",
    year:"1"
  },{
    id:2,
    date:"10 - 26 July",
    subjects:"Research Methodology & Hermeneutics of Contextual Theological Methods",
    by:"Dr. Fr. Vincent Kundukulam",
    year:"1"
  },{
    id:3,
    date:"29 - 16 August",
    subjects:"Modern Trends in Theological Anthropology",
    by:"Dr. Anil  D’ Almeida SJ",
    year:"1"
  },{
    id:4,
    date:"Aug 19 – 06 Sep",
    subjects:"Exploring Trends in Trinitarian Theology",
    by:"Dr.  Jossie Lobo SJ",
    year:"1"
  },{
    id:5,
    date:"Sep 09 – 27 Sep",
    subjects:"Contemporary Issues in Christology",
    by:"Dr. Thomas Kuriakose SJ",
    year:"1"
  },{
    id:6,
    date:"Sep 30 – 25 Oct & Oct 12  – 10 ",
    subjects:"Biblical Hermeneutics",
    by:"Dr. Valan C. Antony SJ",
    year:"1"
  },{
    id:7,
    date:"Oct 28 –22 Nov & Nov 1-9 ",
    subjects:"Theology of Councils: Emphasis on Vat-II",
    by:"Dr. Jesu Raj SVD",
    year:"1"
  },{
    id:8,
    date:"Nov25-20 Dec & (Seminar:28-29Nov)",
    subjects:"Sacraments: Paradigm Shifts & Indian Samskaras",
    by:"Dr. Rajakumar Joseph SJ",
    year:"1"
  },{
    id:9,
    date:"Jan 06 - 24",
    subjects:"Contemporary Trends in Ecclesiology",
    by:"Dr. Sr. Evelyn Monteiro",
    year:"1"
  },{
    id:10,
    date:"Jan 27 – 14 Feb",
    subjects:"Theology of Religions",
    by:"Dr. Victor Edwin Joseph SJ",
    year:"1"
  },{
    id:11,
    date:"Feb 17 – April 07",
    subjects:"Guided Paper (1)",
    year:"1"
  },{
    id:12,
    date:"Feb 24 – 07 Mar",
    subjects:"Greek",
    by:"Dr. Jimmy Dhabi SJ",
    year:"1"
  },{
    id:13,
    date:"Mar 10 – 28 Mar",
    subjects:"Latin",
    by:"Dr. Jimmy Dhabi SJ and Dr. Augustine SJ",
    year:"1"
  },{
    id:14,
    date:"April 8 – 30 April, Submit-July 11, ‘25",
    subjects:"Guided Paper (2)",
    year:"1"
  },{
    id:15,
    date:"July 14 – 1 Aug, 2025",
    subjects:"Indian Christology: Method & Issues",
    by:"Dr. Jacob Parapally MSFS",
    year:"2"
  },{
    id:16,
    date:"Aug 04 - 22",
    subjects:"Peace and Reconciliation in the Bible",
    by:"Dr. Edwin Rodrigues SJ",
    year:"2"
  },{
    id:17,
    date:"Sep 25 – 17 Oct",
    subjects:"Political Theology: Public & Subaltern & Liberation Theology",
    by:"Dr. Gnana Patrick",
    year:"2"
  },{
    id:18,
    date:"Oct 20 – 07 Nov",
    subjects:"Issues in Bio - Ethics & Guarding the Vulnerable Adults",
    by:"Dr. Stanislaus  Alla SJ",
    year:"2"
  },{
    id:19,
    date:"Nov 10 – 28 Nov",
    subjects:"Integral Ecology / Mariology",
    by:"Dr. Jose Mathew SJ",
    year:"2"
  },{
    id:20,
    date:"Dec 01 – 19 Dec",
    subjects:"Inculturation, Evangelization & Inter-Rel. Dial ",
    by:"Dr. Rajakumar & VJ-TEAM Teaching",
    year:"2"
  },{
    id:21,
    date:"Dec 10 – 25 Mar ",
    subjects:"Dissertation Paper (3)",
    year:"2"
  },{
    id:1,
    date:"08 - 09 July",
    subjects:"Inauguration and Orientation",
    year:"1"
  }]

  export const newlist=[
    {
      id:1,
      title:"ave",
      imgSrc:require('./assests/images/photos/ave.jpg')
    },
    {
      id:2,
      title:"vjtr",
      imgSrc:require('./assests/images/photos/vjtr.jpg')
    }
  ]

  export const phoneCode=[
    {
      "Country": "USA",
      "Code": "+1"
    },
    {
      "Country": "RUS",
      "Code": "+7"
    },
    {
      "Country": "EGY",
      "Code": "+20"
    },
    {
      "Country": "ZAF",
      "Code": "+27"
    },
    {
      "Country": "GRC",
      "Code": "+30"
    },
    {
      "Country": "NLD",
      "Code": "+31"
    },
    {
      "Country": "BEL",
      "Code": "+32"
    },
    {
      "Country": "FRA",
      "Code": "+33"
    },
    {
      "Country": "ESP",
      "Code": "+34"
    },
    {
      "Country": "HUN",
      "Code": "+36"
    },
    {
      "Country": "ITA",
      "Code": "+39"
    },
    {
      "Country": "ROU",
      "Code": "+40"
    },
    {
      "Country": "CHE",
      "Code": "+41"
    },
    {
      "Country": "AUT",
      "Code": "+43"
    },
    {
      "Country": "GBR",
      "Code": "+44"
    },
    {
      "Country": "DNK",
      "Code": "+45"
    },
    {
      "Country": "SWE",
      "Code": "+46"
    },
    {
      "Country": "NOR",
      "Code": "+47"
    },
    {
      "Country": "POL",
      "Code": "+48"
    },
    {
      "Country": "DEU",
      "Code": "+49"
    },
    {
      "Country": "PER",
      "Code": "+51"
    },
    {
      "Country": "MEX",
      "Code": "+52"
    },
    {
      "Country": "CUB",
      "Code": "+53"
    },
    {
      "Country": "ARG",
      "Code": "+54"
    },
    {
      "Country": "BRA",
      "Code": "+55"
    },
    {
      "Country": "CHL",
      "Code": "+56"
    },
    {
      "Country": "COL",
      "Code": "+57"
    },
    {
      "Country": "VEN",
      "Code": "+58"
    },
    {
      "Country": "MYS",
      "Code": "+60"
    },
    {
      "Country": "AUS",
      "Code": "+61"
    },
    {
      "Country": "IDN",
      "Code": "+62"
    },
    {
      "Country": "PHL",
      "Code": "+63"
    },
    {
      "Country": "NZL",
      "Code": "+64"
    },
    {
      "Country": "SGP",
      "Code": "+65"
    },
    {
      "Country": "THA",
      "Code": "+66"
    },
    {
      "Country": "JPN",
      "Code": "+81"
    },
    {
      "Country": "KOR",
      "Code": "+82"
    },
    {
      "Country": "VNM",
      "Code": "+84"
    },
    {
      "Country": "CHN",
      "Code": "+86"
    },
    {
      "Country": "TUR",
      "Code": "+90"
    },
    {
      "Country": "IND",
      "Code": "+91"
    },
    {
      "Country": "PAK",
      "Code": "+92"
    },
    {
      "Country": "AFG",
      "Code": "+93"
    },
    {
      "Country": "LKA",
      "Code": "+94"
    },
    {
      "Country": "MMR",
      "Code": "+95"
    },
    {
      "Country": "IRN",
      "Code": "+98"
    },
    {
      "Country": "SSD",
      "Code": "+211"
    },
    {
      "Country": "ESH",
      "Code": "+212"
    },
    {
      "Country": "DZA",
      "Code": "+213"
    },
    {
      "Country": "TUN",
      "Code": "+216"
    },
    {
      "Country": "LBY",
      "Code": "+218"
    },
    {
      "Country": "GMB",
      "Code": "+220"
    },
    {
      "Country": "SEN",
      "Code": "+221"
    },
    {
      "Country": "MRT",
      "Code": "+222"
    },
    {
      "Country": "MLI",
      "Code": "+223"
    },
    {
      "Country": "GIN",
      "Code": "+224"
    },
    {
      "Country": "CIV",
      "Code": "+225"
    },
    {
      "Country": "BFA",
      "Code": "+226"
    },
    {
      "Country": "NER",
      "Code": "+227"
    },
    {
      "Country": "TGO",
      "Code": "+228"
    },
    {
      "Country": "BEN",
      "Code": "+229"
    },
    {
      "Country": "MUS",
      "Code": "+230"
    },
    {
      "Country": "LBR",
      "Code": "+231"
    },
    {
      "Country": "SLE",
      "Code": "+232"
    },
    {
      "Country": "GHA",
      "Code": "+233"
    },
    {
      "Country": "NGA",
      "Code": "+234"
    },
    {
      "Country": "TCD",
      "Code": "+235"
    },
    {
      "Country": "CAF",
      "Code": "+236"
    },
    {
      "Country": "CMR",
      "Code": "+237"
    },
    {
      "Country": "CPV",
      "Code": "+238"
    },
    {
      "Country": "STP",
      "Code": "+239"
    },
    {
      "Country": "GNQ",
      "Code": "+240"
    },
    {
      "Country": "GAB",
      "Code": "+241"
    },
    {
      "Country": "COG",
      "Code": "+242"
    },
    {
      "Country": "COD",
      "Code": "+243"
    },
    {
      "Country": "AGO",
      "Code": "+244"
    },
    {
      "Country": "GNB",
      "Code": "+245"
    },
    {
      "Country": "IOT",
      "Code": "+246"
    },
    {
      "Country": "SYC",
      "Code": "+248"
    },
    {
      "Country": "SDN",
      "Code": "+249"
    },
    {
      "Country": "RWA",
      "Code": "+250"
    },
    {
      "Country": "ETH",
      "Code": "+251"
    },
    {
      "Country": "SOM",
      "Code": "+252"
    },
    {
      "Country": "DJI",
      "Code": "+253"
    },
    {
      "Country": "KEN",
      "Code": "+254"
    },
    {
      "Country": "TZA",
      "Code": "+255"
    },
    {
      "Country": "UGA",
      "Code": "+256"
    },
    {
      "Country": "BDI",
      "Code": "+257"
    },
    {
      "Country": "MOZ",
      "Code": "+258"
    },
    {
      "Country": "ZMB",
      "Code": "+260"
    },
    {
      "Country": "MDG",
      "Code": "+261"
    },
    {
      "Country": "REU",
      "Code": "+262"
    },
    {
      "Country": "ZWE",
      "Code": "+263"
    },
    {
      "Country": "NAM",
      "Code": "+264"
    },
    {
      "Country": "MWI",
      "Code": "+265"
    },
    {
      "Country": "LSO",
      "Code": "+266"
    },
    {
      "Country": "BWA",
      "Code": "+267"
    },
    {
      "Country": "SWZ",
      "Code": "+268"
    },
    {
      "Country": "COM",
      "Code": "+269"
    },
    {
      "Country": "SHN",
      "Code": "+290"
    },
    {
      "Country": "ERI",
      "Code": "+291"
    },
    {
      "Country": "ABW",
      "Code": "+297"
    },
    {
      "Country": "FRO",
      "Code": "+298"
    },
    {
      "Country": "GRL",
      "Code": "+299"
    },
    {
      "Country": "GIB",
      "Code": "+350"
    },
    {
      "Country": "PRT",
      "Code": "+351"
    },
    {
      "Country": "LUX",
      "Code": "+352"
    },
    {
      "Country": "IRL",
      "Code": "+353"
    },
    {
      "Country": "ISL",
      "Code": "+354"
    },
    {
      "Country": "ALB",
      "Code": "+355"
    },
    {
      "Country": "MLT",
      "Code": "+356"
    },
    {
      "Country": "CYP",
      "Code": "+357"
    },
    {
      "Country": "FIN",
      "Code": "+358"
    },
    {
      "Country": "BGR",
      "Code": "+359"
    },
    {
      "Country": "LTU",
      "Code": "+370"
    },
    {
      "Country": "LVA",
      "Code": "+371"
    },
    {
      "Country": "EST",
      "Code": "+372"
    },
    {
      "Country": "MDA",
      "Code": "+373"
    },
    {
      "Country": "ARM",
      "Code": "+374"
    },
    {
      "Country": "BLR",
      "Code": "+375"
    },
    {
      "Country": "AND",
      "Code": "+376"
    },
    {
      "Country": "MCO",
      "Code": "+377"
    },
    {
      "Country": "SMR",
      "Code": "+378"
    },
    {
      "Country": "UKR",
      "Code": "+380"
    },
    {
      "Country": "SRB",
      "Code": "+381"
    },
    {
      "Country": "MNE",
      "Code": "+382"
    },
    {
      "Country": "XKX",
      "Code": "+383"
    },
    {
      "Country": "HRV",
      "Code": "+385"
    },
    {
      "Country": "SVN",
      "Code": "+386"
    },
    {
      "Country": "BIH",
      "Code": "+387"
    },
    {
      "Country": "MKD",
      "Code": "+389"
    },
    {
      "Country": "CZE",
      "Code": "+420"
    },
    {
      "Country": "SVK",
      "Code": "+421"
    },
    {
      "Country": "LIE",
      "Code": "+423"
    },
    {
      "Country": "FLK",
      "Code": "+500"
    },
    {
      "Country": "BLZ",
      "Code": "+501"
    },
    {
      "Country": "GTM",
      "Code": "+502"
    },
    {
      "Country": "SLV",
      "Code": "+503"
    },
    {
      "Country": "HND",
      "Code": "+504"
    },
    {
      "Country": "NIC",
      "Code": "+505"
    },
    {
      "Country": "CRI",
      "Code": "+506"
    },
    {
      "Country": "PAN",
      "Code": "+507"
    },
    {
      "Country": "SPM",
      "Code": "+508"
    },
    {
      "Country": "HTI",
      "Code": "+509"
    },
    {
      "Country": "BLM",
      "Code": "+590"
    },
    {
      "Country": "BOL",
      "Code": "+591"
    },
    {
      "Country": "GUY",
      "Code": "+592"
    },
    {
      "Country": "ECU",
      "Code": "+593"
    },
    {
      "Country": "PRY",
      "Code": "+595"
    },
    {
      "Country": "SUR",
      "Code": "+597"
    },
    {
      "Country": "URY",
      "Code": "+598"
    },
    {
      "Country": "ANT",
      "Code": "+599"
    },
    {
      "Country": "TLS",
      "Code": "+670"
    },
    {
      "Country": "ATA",
      "Code": "+672"
    },
    {
      "Country": "BRN",
      "Code": "+673"
    },
    {
      "Country": "NRU",
      "Code": "+674"
    },
    {
      "Country": "PNG",
      "Code": "+675"
    },
    {
      "Country": "TON",
      "Code": "+676"
    },
    {
      "Country": "SLB",
      "Code": "+677"
    },
    {
      "Country": "VUT",
      "Code": "+678"
    },
    {
      "Country": "FJI",
      "Code": "+679"
    },
    {
      "Country": "PLW",
      "Code": "+680"
    },
    {
      "Country": "WLF",
      "Code": "+681"
    },
    {
      "Country": "COK",
      "Code": "+682"
    },
    {
      "Country": "NIU",
      "Code": "+683"
    },
    {
      "Country": "WSM",
      "Code": "+685"
    },
    {
      "Country": "KIR",
      "Code": "+686"
    },
    {
      "Country": "NCL",
      "Code": "+687"
    },
    {
      "Country": "TUV",
      "Code": "+688"
    },
    {
      "Country": "PYF",
      "Code": "+689"
    },
    {
      "Country": "TKL",
      "Code": "+690"
    },
    {
      "Country": "FSM",
      "Code": "+691"
    },
    {
      "Country": "MHL",
      "Code": "+692"
    },
    {
      "Country": "PRK",
      "Code": "+850"
    },
    {
      "Country": "HKG",
      "Code": "+852"
    },
    {
      "Country": "MAC",
      "Code": "+853"
    },
    {
      "Country": "KHM",
      "Code": "+855"
    },
    {
      "Country": "LAO",
      "Code": "+856"
    },
    {
      "Country": "BGD",
      "Code": "+880"
    },
    {
      "Country": "TWN",
      "Code": "+886"
    },
    {
      "Country": "MDV",
      "Code": "+960"
    },
    {
      "Country": "LBN",
      "Code": "+961"
    },
    {
      "Country": "JOR",
      "Code": "+962"
    },
    {
      "Country": "SYR",
      "Code": "+963"
    },
    {
      "Country": "IRQ",
      "Code": "+964"
    },
    {
      "Country": "KWT",
      "Code": "+965"
    },
    {
      "Country": "SAU",
      "Code": "+966"
    },
    {
      "Country": "YEM",
      "Code": "+967"
    },
    {
      "Country": "OMN",
      "Code": "+968"
    },
    {
      "Country": "PSE",
      "Code": "+970"
    },
    {
      "Country": "ARE",
      "Code": "+971"
    },
    {
      "Country": "ISR",
      "Code": "+972"
    },
    {
      "Country": "BHR",
      "Code": "+973"
    },
    {
      "Country": "QAT",
      "Code": "+974"
    },
    {
      "Country": "BTN",
      "Code": "+975"
    },
    {
      "Country": "MNG",
      "Code": "+976"
    },
    {
      "Country": "NPL",
      "Code": "+977"
    },
    {
      "Country": "TJK",
      "Code": "+992"
    },
    {
      "Country": "TKM",
      "Code": "+993"
    },
    {
      "Country": "AZE",
      "Code": "+994"
    },
    {
      "Country": "GEO",
      "Code": "+995"
    },
    {
      "Country": "KGZ",
      "Code": "+996"
    },
    {
      "Country": "UZB",
      "Code": "+998"
    }
  ];

  export const optionalCoursesList=[{
    id:1,
    course:"Biblical Greek",
    credits:1
  },{
    id:2,
    course:"Book of Revelation",
    credits:1
  },{
    id:3,
    course:"Canon Law for Religious",
    credits:1
  },{
    id:4,
    course:"Letter to the Hebrews",
    credits:1
  },{
    id:5,
    course:"Judaism",
    credits:1
  },{
    id:6,
    course:"Sufism",
    credits:1
  },{
    id:7,
    course:"Themes in Feminist Theology",
    credits:1
  },{
    id:8,
    course:"Ethics of Child Protection",
    credits:1
  },{
    id:9,
    course:"Pastoral Liturgy",
    credits:1
  },{
    id:10,
    course:"Canon Law on Sacraments",
    credits:1
  },{
    id:11,
    course:"Indians Constitution",
    credits:1
  },{
    id:12,
    course:"Integral Ecology",
    credits:1
  },{
    id:13,
    course:"Peace and Reconciliation",
    credits:1
  },{
    id:14,
    course:"Ignatian Spirituality",
    credits:1
  }];

  export const vjcTopStoriesList=[{
    clientID:"VJCollege",
timelineID:"1",
timelineDisplay:"Yes",
timelineTitle:"INTERNATIONAL WOMEN’S DAY CELEBRATION",
timelineHeader:"08 MARCH, 2024",
timelineDescription:"On the occasion of International Women’s Day, 8th March 2024, an extension lecture was organized to commemorate the significance of the day and to shed light on the challenges and achievements of women globally. The event was graced by esteemed speaker and participants, contributing to a meaningful discussion on the empowerment of women and gender equality. Dr. Ella Sonawane, the Associate General Secretary of ISPCK, Delhi, served as the spokesperson for International Women’s Day. Dr. Ella, a strong advocate for women’s rights and empowerment, shared her insights and experiences on the occasion. The principal, Fr. Rajkumar introduced Dr. Ella, highlighting her remarkable achievements as a strong woman, author, speaker, and social service advocate. On this special occasion all the sisters present were felicitated with a bouquet, symbolizing appreciation and recognition for their roles and contributions. Dr. Ella shared her insights into the roles and challenges faced by women in society, drawing attention to patriarchal norms, bias, and the lack of political and economic representation. And also Dr. Ella cited examples from Uttar Pradesh and Punjab where empowered women have made significant progress, enabling them to voice their concerns and participate actively in society. The theme of inclusion resonated throughout the discussion, with emphasis placed on encouraging a culture where women are respected and empowered. Dr. Ella stressed the importance of men embracing feminism and supporting gender equality initiatives, emphasizing that the empowerment of women is crucial for societal development. So, the International Women’s Day extension lecture provided a platform for meaningful dialogue and reflection on the status of women in society. Through the insightful discussions and reflections, participants were inspired to champion the cause of gender equality and work towards creating a more inclusive and equitable world for all.",
timelineImage:"",
updatedBy:"TEST",
updatedOn:"TEST",
timelineColor:"#4CADAD"
  },{
    clientID:"VJCollege",
timelineID:"1",
timelineDisplay:"Yes",
timelineTitle:"INTERNATIONAL WOMEN’S DAY CELEBRATION",
timelineHeader:"08 MARCH, 2024",
timelineDescription:"On the occasion of International Women’s Day, 8th March 2024, an extension lecture was organized to commemorate the significance of the day and to shed light on the challenges and achievements of women globally. The event was graced by esteemed speaker and participants, contributing to a meaningful discussion on the empowerment of women and gender equality. Dr. Ella Sonawane, the Associate General Secretary of ISPCK, Delhi, served as the spokesperson for International Women’s Day. Dr. Ella, a strong advocate for women’s rights and empowerment, shared her insights and experiences on the occasion. The principal, Fr. Rajkumar introduced Dr. Ella, highlighting her remarkable achievements as a strong woman, author, speaker, and social service advocate. On this special occasion all the sisters present were felicitated with a bouquet, symbolizing appreciation and recognition for their roles and contributions. Dr. Ella shared her insights into the roles and challenges faced by women in society, drawing attention to patriarchal norms, bias, and the lack of political and economic representation. And also Dr. Ella cited examples from Uttar Pradesh and Punjab where empowered women have made significant progress, enabling them to voice their concerns and participate actively in society. The theme of inclusion resonated throughout the discussion, with emphasis placed on encouraging a culture where women are respected and empowered. Dr. Ella stressed the importance of men embracing feminism and supporting gender equality initiatives, emphasizing that the empowerment of women is crucial for societal development. So, the International Women’s Day extension lecture provided a platform for meaningful dialogue and reflection on the status of women in society. Through the insightful discussions and reflections, participants were inspired to champion the cause of gender equality and work towards creating a more inclusive and equitable world for all.",
timelineImage:"",
updatedBy:"TEST",
updatedOn:"TEST",
timelineColor:"#4CADAD"
  },{
    clientID:"VJCollege",
timelineID:"1",
timelineDisplay:"Yes",
timelineTitle:"INTERNATIONAL WOMEN’S DAY CELEBRATION",
timelineHeader:"08 MARCH, 2024",
timelineDescription:"On the occasion of International Women’s Day, 8th March 2024, an extension lecture was organized to commemorate the significance of the day and to shed light on the challenges and achievements of women globally. The event was graced by esteemed speaker and participants, contributing to a meaningful discussion on the empowerment of women and gender equality. Dr. Ella Sonawane, the Associate General Secretary of ISPCK, Delhi, served as the spokesperson for International Women’s Day. Dr. Ella, a strong advocate for women’s rights and empowerment, shared her insights and experiences on the occasion. The principal, Fr. Rajkumar introduced Dr. Ella, highlighting her remarkable achievements as a strong woman, author, speaker, and social service advocate. On this special occasion all the sisters present were felicitated with a bouquet, symbolizing appreciation and recognition for their roles and contributions. Dr. Ella shared her insights into the roles and challenges faced by women in society, drawing attention to patriarchal norms, bias, and the lack of political and economic representation. And also Dr. Ella cited examples from Uttar Pradesh and Punjab where empowered women have made significant progress, enabling them to voice their concerns and participate actively in society. The theme of inclusion resonated throughout the discussion, with emphasis placed on encouraging a culture where women are respected and empowered. Dr. Ella stressed the importance of men embracing feminism and supporting gender equality initiatives, emphasizing that the empowerment of women is crucial for societal development. So, the International Women’s Day extension lecture provided a platform for meaningful dialogue and reflection on the status of women in society. Through the insightful discussions and reflections, participants were inspired to champion the cause of gender equality and work towards creating a more inclusive and equitable world for all.",
timelineImage:"",
updatedBy:"TEST",
updatedOn:"TEST",
timelineColor:"#4CADAD"
  },{
    clientID:"VJCollege",
timelineID:"1",
timelineDisplay:"Yes",
timelineTitle:"INTERNATIONAL WOMEN’S DAY CELEBRATION",
timelineHeader:"08 MARCH, 2024",
timelineDescription:"On the occasion of International Women’s Day, 8th March 2024, an extension lecture was organized to commemorate the significance of the day and to shed light on the challenges and achievements of women globally. The event was graced by esteemed speaker and participants, contributing to a meaningful discussion on the empowerment of women and gender equality. Dr. Ella Sonawane, the Associate General Secretary of ISPCK, Delhi, served as the spokesperson for International Women’s Day. Dr. Ella, a strong advocate for women’s rights and empowerment, shared her insights and experiences on the occasion. The principal, Fr. Rajkumar introduced Dr. Ella, highlighting her remarkable achievements as a strong woman, author, speaker, and social service advocate. On this special occasion all the sisters present were felicitated with a bouquet, symbolizing appreciation and recognition for their roles and contributions. Dr. Ella shared her insights into the roles and challenges faced by women in society, drawing attention to patriarchal norms, bias, and the lack of political and economic representation. And also Dr. Ella cited examples from Uttar Pradesh and Punjab where empowered women have made significant progress, enabling them to voice their concerns and participate actively in society. The theme of inclusion resonated throughout the discussion, with emphasis placed on encouraging a culture where women are respected and empowered. Dr. Ella stressed the importance of men embracing feminism and supporting gender equality initiatives, emphasizing that the empowerment of women is crucial for societal development. So, the International Women’s Day extension lecture provided a platform for meaningful dialogue and reflection on the status of women in society. Through the insightful discussions and reflections, participants were inspired to champion the cause of gender equality and work towards creating a more inclusive and equitable world for all.",
timelineImage:"",
updatedBy:"TEST",
updatedOn:"TEST",
timelineColor:"#4CADAD"
  }]